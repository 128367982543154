import {Fragment, useContext, useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import { FetchContext } from 'App/Strapi/FetchContext';
import { stringify } from 'qs';
import Container from "UI/App/Components/Container/Container";
import { toast } from 'react-toastify';
import Table from 'UI/App/Components/Table/Table';
import { usePagination } from 'UI/App/Components/Pagination/Pagination';
import { formatDateWithoutTime, formatTime } from 'App/Util/format';
import Icon from "UI/App/Components/Icon/Icon";
import {openPopup} from "UI/App/Components/Popup/Popup";
import User from "App/Strapi/User";
import IF from "UI/App/Components/Conditional/IF";
import OrderTimerEditPopup from "UI/App/Partials/Content/CRM/Orders/OrderTimer/OrderTimerEditPopup";
import {getSecondsMinusBreaks} from "App/Util/transform";
import { exportObject } from 'App/Util/exportObject';

export default function ViewTimers() {
    const params = useParams();
    const { authAxios } = useContext(FetchContext);
    // create a state for the table data
    const [tableData, setTableData] = useState([]);

    // states to store filter options in
    const [customers, setCustomers] = useState([])
    const [projects, setProjects] = useState([])
    const [machines, setMachines] = useState([])

    const [timerOpenInPopup, setTimerOpenInPopup] = useState(null);

    // create a state for the table columns (sort order and column field)
    const [tableColumns, setTableColumns] = useState({
        sort: {
            column: 'id',
            direction: 'desc'
        },
        fields: [

            {
                name: 'Datum', field: 'start', sortable: true,
            },
            {
                name: 'Gebruiker', field: 'user.username', sortable: true,
            },
            {
                name: 'Aantal uren', field: '', sortable: false,
            },
            {
                name: 'Klant', field: 'order.company.name', sortable: true,
            },
            {
                name: 'Order', field: 'order.name', sortable: true,
            },
            {
                name: 'Machine', field: 'timerFor', sortable: true,
            }
        ]
    });

    function editTimer(timer) {
        setTimerOpenInPopup(timer);
        openPopup('editTimerPopup');
    }

    function onTimerEditPopupClose({ detail }) {
        if (detail.popupID !== 'editTimerPopup') return;
        setTimerOpenInPopup(null);
    }

    function editCallback(timer) {
        reloadData();
    }

    useEffect(() => {
        document.addEventListener('close-popup', onTimerEditPopupClose);

        return () => {
            document.removeEventListener('close-popup', onTimerEditPopupClose);
        }
    }, []);

    // Enable pagination
    const {
        paging,
        filtering,
        currentPage,
        resultsPerPage,
        setTotalPages,
        setTotalResults,
        filterQuery,
        filterValues,
        paginationStateLoaded,
        setFilterValues,
        reloadData
    } = usePagination({
        storageKey: 'order-timers',
        searchSettings: {
            enabled: true,
            strapiFields: [
                '[user][username]',
                '[order][number]',
                '[order][description]',
                '[order][company][name]',
            ]
        },
        filters: [
            {
                label: "van",
                name: "start",
                type: "date",
                callbackFunction: (e) => {

                    if (e.currentValue === "" || e.currentValue === undefined) return;
                    // create the custom filter object
                    return {
                        [e.strapiFilterField]: {
                            [e.strapiFilter]: e.currentValue
                        }
                    }
                },
                strapiFilterField: 'start',
                strapiFilter: '$gte'
            },
            {
                label: "tot",
                name: "end",
                type: "date",
                callbackFunction: (e) => {
                    if (e.currentValue === "" || e.currentValue === undefined) return;
                    // create the custom filter object
                    return {
                        $or: [
                            {
                                [e.strapiFilterField]: {

                                    [e.strapiFilter]: e.currentValue,

                                }
                            },
                            {// if a timer doesn't have an end we can accept null as 'now'
                                [e.strapiFilterField]: {
                                    $null: true
                                }
                            }
                        ]

                    }
                },
                strapiFilterField: 'end',
                strapiFilter: '$lte'
            },
            {
                name: "company",
                type: "select",
                options: [
                    {
                        name: "Alle klanten", value: '*',
                    },
                    ...customers
                ],
                value: '*',
                strapiFilterFields: ['[order][company][id]'],
            },
            {
                name: "project",
                type: "select",
                options: [
                    {
                        name: "Alle projecten", value: '*'
                    },
                    ...projects
                ],
                value: '*',
                strapiFilterFields: ['[order][id]'],
            },
            {
                //label: "Machine:",
                name: "machine",
                type: "select",
                options: [
                    {
                        name: "Alle machines", value: '*'
                    },
                    ...machines
                ],
                value: '*',
                strapiFilterFields: ['timerFor'],
            },

        ],
        htmlElements: [
            <button onClick={() => { exportTimers() }} className='btn--black btn btn--icon-right'>
                Exporteren <Icon name='arrow-down' />
            </button>,
        ],
        buttonCollapseBreakpoint: 1960,
        resultsPerPageBreakpoint: 1960,
        paginationBreakpoint: 1960,
        resultCountBreakpoint: 1850,
        filterPopupBreakpoint: 99999, // As requested all filters should be in a popup, setting the width to something high will enforce this.
        showFilterClearButton: true,
    });

    // get all the filter options
    useEffect(() => {
        // get the companies/customers
        authAxios.get(`/crm/companies/all`).then(({ data }) => {
            setCustomers(
                data
                    .map(customer => ({ value: customer?.id, name: customer?.name.trim() }))
                    .sort((a,b) => a.name.localeCompare(b.name))
            );
        }).catch((exception) => {
            toast.error(
                `Er is iets fout gegaan met het ophalen van de bedrijven ${exception?.response?.status ? `(${exception.response?.status})` : ''
                } `
            );
            console.error(exception);
        });

        // by specifically populating the needed fields speed up response times
        const orderQuery = stringify({
            populate: {
                project: {
                    fields: ["id"]
                },
            },
            fields: ["id", "number", "description"],
            sort: "id:desc",
        })

        // get the orders/projects
        authAxios.get(`/orders/all?${orderQuery}`).then(({ data }) => {
            setProjects(data.map((entry) => { return { value: entry?.id, name: `${entry?.number} - ${entry?.description}` } }))
        }).catch((exception) => {
            toast.error(
                `Er is iets fout gegaan met het ophalen van de projecten ${exception?.response?.status ? `(${exception.response?.status})` : ''
                } `
            );
            console.error(exception);
        });

        // get the machines
        authAxios.get(`/orders/timers/all-actions`).then(({ data }) => {
            setMachines(data.map((entry) => { return { value: entry?.value, name: entry?.value } }))
        }).catch((exception) => {
            toast.error(
                `Er is iets fout gegaan met het ophalen van de machines ${exception?.response?.status ? `(${exception.response?.status})` : ''
                } `
            );
            console.error(exception);
        });
    }, [])

    // on params set/change
    useEffect(() => {
        // check for orderId in the params
        if (params?.orderId !== undefined) {
            //  set the filter to only return results from that order
            setFilterValues(prev => ({
                ...prev,
                project: params?.orderId,
            }))
        }
    }, [params])

    // get the table data using the useEffect hook
    useEffect(() => {

        // wait for pagination
        if (!paginationStateLoaded) return;

        // build the pagination query
        let query = buildSearchParams(resultsPerPage, currentPage, tableColumns);
        authAxios
            .get(`/orders/timers?${query}&${filterQuery}`)
            .then(({ data }) => {
                // create temp array to store formated data in
                let tableDataArray = []

                const hasEditRights = User.hasRole(['admin', 'management', 'productie manager', 'sales']);

                // format data to be usable for table component
                for (const entry of data.results) {

                    // calculate the hours spent on this order
                    const entrySeconds = getSecondsMinusBreaks(entry.start, entry.end, entry.breaks);
                    let hours = formatTime(entrySeconds / 1000 / 60 / 60);

                    tableDataArray.push(
                        {
                            attributes: {
                                id: entry.id
                            },
                            data: [
                                formatDateWithoutTime(entry?.start) ?? '-', // date
                                entry?.user?.username ?? '-',      // user
                                entry?.order?.company?.name ?? '-',   // customer name
                                entry?.order === null ? '-' : `${entry?.order?.number ?? "n.n."} - ${entry?.order?.description ?? "n.n."}`,   // project
                                entry?.timerFor ?? '-',   // activity
                                hours,   // amount of hours
                                <IF condition={hasEditRights}>
                                    <button className={'btn--transparent'} onClick={() => editTimer(entry)}><Icon name={'pen-to-square'} /></button>
                                </IF>
                            ],

                        }
                    )
                }
                // save table data in useState
                setTableData(tableDataArray)

                // Set pagination data
                const paginationData = data?.pagination;
                setTotalPages(paginationData?.pageCount);
                setTotalResults(paginationData?.total);

            }).catch((exception) => {
                toast.error(
                    `Er is iets fout gegaan met het ophalen van de timers ${exception?.response?.status ? `(${exception.response?.status})` : ''
                    } `
                );

                console.error(exception);
            });
    }, [currentPage, resultsPerPage, authAxios, setTotalPages, setTotalResults, filterQuery, tableColumns, paginationStateLoaded]);


    function buildSearchParams(resultsPerPage, currentPage, tableColumns) {
        let queryParams = {

            page: currentPage,
            pageSize: resultsPerPage,
            sort: `${tableColumns.sort.column}:${tableColumns.sort.direction}`,
            filters: {
                order: {
                    id: {
                        $notNull: true
                    }

                }

            }
        };

        return stringify(queryParams, {
            encodeValuesOnly: true // prettify URL
        });
    }

    function exportTimers() {
        authAxios
            .get(`/orders/timers/all?${filterQuery}`)
            .then(({ data }) => {

                let formatedOutput = data.map((el) => {
                    // calculate the hours spent on this order
                    const entrySeconds = getSecondsMinusBreaks(el.start, el.end, el.breaks);
                    let hours = formatTime(entrySeconds / 1000 / 60 / 60);

                    return {
                        "Datum": el?.start,
                        "Medewerker": el?.user?.username ?? "N.V.T.",
                        "Klant": el?.order?.company?.name ?? "N.V.T.",
                        "Project": el?.order?.number ?? "N.V.T.",
                        "Machine": el?.timerFor ?? "N.V.T.",
                        "Uren besteed": hours,
                        "Minuten overwerk": el.minutesOvertime,
                        "Minuten te laat": el.minutesLate,
                    }
                })
                exportObject(formatedOutput, {
                    filename: `Urenverantwoording_export-${new Date().toLocaleDateString("nl-NL", { year: "numeric", month: "2-digit", day: "2-digit" })}`, // date in DD-MM-YYYY
                    fieldSeparator: ';',
                    decimalSeparator: 'locale',
                    useKeysAsHeaders: true,
                    showColumnHeaders: true,
                    useBom: true,
                })
            })
    }

    return (
        <Fragment>
            <div>
                {filtering}
                <Container>
                    <Table
                        headers={[
                            'Datum',
                            'Medewerker',
                            'Klant',
                            'Project',
                            'Machine',
                            'Uren',
                            ''
                        ]}
                        structure={{
                            default: ['130px', '20%', '30%', '25%', '25%', '100px', '65px']
                        }}
                        data={tableData}
                        tableColumns={tableColumns}
                        setTableColumns={setTableColumns}
                        hover
                        border='row'
                        overflowText={true}
                    />
                </Container>
                {paging}
            </div>

            <OrderTimerEditPopup timer={timerOpenInPopup} callback={editCallback} />
        </Fragment>
    )

}
