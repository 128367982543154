import { closePopup, openPopup } from "UI/App/Components/Popup/Popup";
import { useEffect, useContext, useState } from "react";
import { toast } from "react-toastify";
import { stringify } from 'qs';
import { FetchContext } from 'App/Strapi/FetchContext';
import Button from "UI/App/Components/Button/Button";
import Grid from "UI/App/Components/Grid/Grid";
import Popup from "UI/App/Components/Popup/Popup";
import Select from "react-select";
import Icon from "UI/App/Components/Icon/Icon";
import AddCompanyBranchPopup from 'UI/App/Components/Popup/Popups/AddCompanyBranchPopup';

export default function OrderAddDeliveryAddressPopup({ setDeliveries = undefined, knownSendingAddresses, setKnownDeliveries = undefined, companyId = undefined, orderId = undefined, knowContactPerson = undefined, setDeletionInfoDelivery = undefined }) {
    const { authAxios } = useContext(FetchContext);

    const [branches, setBranches] = useState([])
    const [branchesOptions, setBranchesOptions] = useState([])
    const [chosenBranch, setChosenBranch] = useState("")
    const [amount, setAmount] = useState("")
    const [IsParentPopupOpen, setIsParentPopupOpen] = useState(true);
    const [company, setCompany] = useState(true);
    const [sendingAddresses, setSendingAddresses] = useState(knownSendingAddresses)

    useEffect(() => {

        if (companyId === undefined) return;
        getCompany(companyId)

    }, [companyId])

    // if the know sending addreses change force the end user to re-evaluate there choiches
    useEffect(() => {
        setAmount("");
        setChosenBranch("");
    }, [knownSendingAddresses])
    useEffect(() => {

        if (orderId === undefined) return;
        getDeliveries(orderId)
    }, [orderId])

    useEffect(() => {
        if (chosenBranch === "") return
        // if we come back form creating an adres/branch reopen the 'parent'
        if (!IsParentPopupOpen) {
            openPopup("OrderAddDeliveryAddres");
        }

    }, [chosenBranch])

    useEffect(() => {
        if (branches === undefined) return;
        const formatedOptions = branches.map((el) => {
            return {
                value: el.id,
                label: el.name
            }
        })
        setBranchesOptions(formatedOptions);

    }, [branches])

    useEffect(() => {
        // since we are getting the entries agian any way update the know deliveries.
        setDeliveries(sendingAddresses?.map((el) => {
            let address = el?.branch?.address?.addressLine1 ?? (el?.branch?.address?.addressLine2 ?? (el?.branch?.address?.addressLine3))
            return [
                el?.branch?.name ?? "--",
                address ?? "--",
                el?.locationManager ?? "--",
                el?.amountSend ?? "--",
                <button className='btn btn--transparent' style={{ padding: 0, paddingRight: 0 }} onClick={() => { setDeletionInfoDelivery(el.id); openPopup("confirm-deletion-delivery-addres"); }}>
                    <Icon name={"trash-can"} style={{ width: '20px', height: '20px' }} />
                </button>
            ]
        }))
        if (setKnownDeliveries !== undefined) {
            setKnownDeliveries(sendingAddresses)
        }
    }, [sendingAddresses])

    async function getCompany(companyId) {
        let query = stringify({
            populate: {
                address: true,
                telephone: true,
                managingCompany: true,
                branches: {
                    populate: {
                        address: true
                    }
                }
            }
        })

        authAxios
            .get(`/crm/companies/${companyId}?${query}`)
            .then(({ data }) => {
                setCompany(data.data)
                setBranches(data.data.branches);

            }).catch((e) => {
                toast.error("Er is een fout opgetreden met het ophalen van de branches")
                console.error(e)
            })
    }

    async function getDeliveries(orderId) {
        let query = stringify({
            populate: {
                sendingAddresses: {
                    populate: {
                        branch: {
                            populate: {
                                address: true,
                            },
                            fields: ["id", "name"]
                        }
                    }
                }
            }
        })
        // Update the order
        await authAxios.get(`/orders/${orderId}?${query}`)
            .then(({ data }) => {
                setSendingAddresses(data.data.sendingAddresses);
            }).catch((e) => {
                toast.error("Er is een fout opgetreden met het ophalen van aflever adressen")
                console.error(e)
            })
    }

    async function handleSubmit(e) {
        e.preventDefault();

        // get the all the info on the branch
        let fullBranch = branches.find((el) => el.id === chosenBranch.value)

        if (orderId === undefined) {
            toast.error("Er is geen order id gegeven!");
            return;
        }

        let knowDeliveries = knownSendingAddresses;

        knowDeliveries.push({
            branch: fullBranch.id,
            locationManager: fullBranch.locationManager ?? knowContactPerson,
            amountSend: amount
        })

        console.log(knowDeliveries);
        // Update the order
        await authAxios.put(`/orders/${orderId}`, {
            data: {
                sendingAddresses: knowDeliveries,
            }
        }).then(() => {
            getDeliveries(orderId);
            toast.success("Aflever adres toegevoegd!")
            closePopup();
        }).catch((e) => {
            toast.error("Er is een fout opgetreden met het aanmaken van een aflever adres")
            console.error(e)
        })

    }

    return (
        <Popup overflow={true} popupID={'OrderAddDeliveryAddres'} title={'Afleveradres toevoegen'}>
            <form onSubmit={(e) => handleSubmit(e)} >
                <Grid gap={"5px"}>
                    <div className='input-group'>
                        <label className={"required"}>Aantal naar dit adres</label>
                        <input required placeholder={"Aantal producten naar het adres..."} type={"number"} min={0} value={amount} onChange={(e) => setAmount(e.target.value)}></input>
                    </div>
                    <div className='input-group'>
                        <label className={"required"}>Kies een adres</label>
                        <Grid
                            customColTemplate={{
                                default: "100% 40px"
                            }}
                            gap={'10px'}
                            alignItems={'center'}
                            columns={2}>
                            <Select
                                required
                                options={branchesOptions}
                                value={chosenBranch}
                                placeholder="Kies een branch"
                                onChange={(e) => setChosenBranch(branchesOptions.find((el) => el.id === e.id))}
                            />
                            <Button style={{ height: "38px", width: "38px", padding: "0px", marginTop: "-5px" }} onClick={(e) => { setIsParentPopupOpen(false); openPopup("createBranchPopup"); }}><Icon name="add" /></Button>
                        </Grid>
                        <Grid alignItems="">
                            <Button disabled={(chosenBranch === "" || amount === "")} type="submit" className={'btn'}>Toevoegen <Icon name={'save'} /></Button>
                        </Grid>
                    </div>
                </Grid>

            </form>
            <AddCompanyBranchPopup setBranches={setBranches} setChosenBranch={setChosenBranch} companyId={companyId} allowSetWithoutSaving={true} />
        </Popup>
    )
}