import IF from "UI/App/Components/Conditional/IF";
import Icon from "UI/App/Components/Icon/Icon";
import Popup from "UI/App/Components/Popup/Popup";
import {useState} from "react";
import {Attachment} from "UI/App/Components/Form/FileUpload/@types";
import './_fileUpload.scss';
import Grid from "../../Grid/Grid";
import Button from "../../Button/Button";

// singular
export function useFileUpload(accept: string,  content: string, icon: string, title: string) {
    const [attachment, setAttachment] = useState<Attachment>({
        name: '',
        url: '',
        file: null,
    })

    return {
        FileUpload: () => <FileUpload
            attachment={attachment}
            setAttachment={setAttachment}
            accept={accept}
            content={content}
            icon={icon}
            title={title}
        />,
        attachment,
        setAttachment
    }
}

export function FileUpload({
    attachment,
    setAttachment,
    accept = '*',
    icon = 'file',
    content = 'Upload een bestand',
    title = 'Bekijk bestand',
    ...props
}: {
    attachment: Attachment,
    setAttachment: (attachment: Attachment|((attachment: Attachment) => Attachment)) => void,
    accept?: string,
    icon?: string,
    content?: string,
    title?: string,
}) {
    return (
        <label className='upload__placeholder' htmlFor='attachment' {...props}>
            <IF condition={!attachment?.url && !attachment?.file}>
                <Icon name={icon}/>
            </IF>
            <IF condition={attachment?.url || attachment?.file}>
                <FilePreview file={attachment?.file} />
                {/* @ts-ignore */}
                <Popup title={title} popupID={'bijlage-preview'}>
                    <img src={attachment?.file ? URL.createObjectURL(attachment.file) : attachment.url} alt={attachment?.file ? attachment?.file?.name : attachment.name}/>
                </Popup>
            </IF>
            <span>{(attachment?.name || attachment?.file) ? (attachment?.file?.name ?? attachment.name) : content}</span>
            <input id='attachment' type='file' accept={accept} onChange={event => {
                setAttachment((attachment: Attachment) => ({
                    ...attachment,
                    file: event.target.files?.[0] ?? null
                }));
            }}/>
        </label>
    );
}

// mutiple
export function useMutipleFileUpload(accept: string, content: string, icon: string, title: string) {
    const [attachments, setAttachments] = useState<Attachment[]>([])

    return {
        MultipleFileUpload: () => <MultipleFileUpload
            attachments={attachments}
            setAttachments={setAttachments}
            accept={accept}
            content={content}
            icon={icon}
            title={title}
        />,
        attachments,
        setAttachments
    }
}
export function MultipleFileUpload({
    attachments,
    setAttachments,
    accept = '*',
    icon = 'file',
    content = 'Upload een bestanden',
    title = 'Bekijk bestanden',
    ...props
}: {
    attachments: Attachment[],
    setAttachments: (attachments: Attachment[] | ((attachments: Attachment[]) => Attachment[])) => void,
    accept?: string,
    icon?: string,
    content?: string,
    title?: string,
}) {
    return (
        <label className='upload__multiple ' htmlFor='attachment' {...props}>
            {attachments?.map((el) => {
                // for each attachment in attachments
                return <div className="upload__wrapper">
                    <IF condition={el?.url || el?.file}>
                        <Grid columns={2} gap={'5px'} customColTemplate={'70% 30%'}>
                            <FilePreview file={el?.file} />
                            <div>
                                <Button className={'btn btn--transparent'} onClick={(event: React.MouseEvent<HTMLElement>) => {
                                    // stop the popup from opening
                                    event.stopPropagation();
                                    event.preventDefault();

                                    // remove this attachment
                                    setAttachments(attachments.filter((_el) => _el.file !== el.file))
                                }} >
                                    <Icon style={{ height: "20px" }} name="trash-can" />
                                </Button>

                            </div>
                        </Grid>

                        {/* @ts-ignore */}
                        <Popup title={title} popupID={'bijlage-preview'}>
                            <img src={el?.file ? URL.createObjectURL(el.file) : el.url} alt={el?.file ? el?.file?.name : el.name} />
                        </Popup>
                    </IF>
                    <span>{(el?.name || el?.file) ? (el?.file?.name ?? el.name) : content}</span>
                </div>
            })}

            <IF condition={attachments?.length <= 0}>
                <Icon name={icon} />
                <input id='attachment' type='file' multiple accept={accept} onChange={event => {
                    let files: Attachment[] = [];

                    // for all selected files 'format' to attachment type
                    for (let file of event.target.files ?? []) {
                        files.push({ file: file ?? null, name: file.name, url: '' })
                    }

                    // add new and old attachments
                    setAttachments((attachments: Attachment[]) => ([
                        ...attachments,
                        ...files
                    ]));
                }} />
            </IF>

        </label>
    )
}

function FilePreview({ file }: { file: File|null }) {
    if (file && /image\/\w+/.test(file?.type)) {
        return <img src={URL.createObjectURL(file)} alt={file?.name} />
    }

    switch (file?.type) {
        case 'application/pdf':
            return <Icon name={'file-pdf'}/>
        default:
            return <Icon name={'file'}/>
    }
}