import React from "react";
import PDF from "App/Util/print";
import {formatName, mmToPx} from "App/Util/format";
import {getNumLines} from "App/Util/getVisibleTextWidth";
import {formatDataForPDF} from "App/Util/pdf";
import {toast} from "react-toastify";
import {download, generateCsv, mkConfig} from "export-to-csv";

export default class DataExporter extends React.Component {
    settings = [];
    authAxios;
    endpoint;
    title;
    download;

    /**
     * CSV Exporter
     * Settings example:
     * const settings = [
     *             {
     *                 header:'Naam',
     *                 data: 'name'
     *             },
     *             {
     *                 header:'Snelheid',
     *                 data: 'grossSpeed'
     *             },
     *             {
     *                 header:'Uurtatief',
     *                 data: 'employeeWage'
     *             },
     *             {
     *                 header:'Opstartkosten',
     *                 data: 'startupCost'
     *             },
     *         ]
     *
     * @param authAxios
     * @param settings
     * @param endpoint
     * @param title
     */
    constructor(authAxios,settings,endpoint,title = 'title',download = true) {
        super();
        this.settings = settings;
        this.authAxios = authAxios;
        this.endpoint = endpoint
        this.title = title;
        this.download = download;
    }

    // Exports as csv
    async exportData(){
        const totalData = await this.authAxios
            .get(this.endpoint)
            .then(({data}) => {
                return data
            }).catch((e) => {
                if (process.env.NODE_ENV === 'development') console.error(e);
                toast.error(`Er is iets fout gegaan bij het ophalen van de export data! ${e?.response?.status && `(${e.response.status})`}`);
            });

        // Add headers
        let csvRows = [];
        for(const data of totalData) {
            let dataRow = [];
            for(const setting of this.settings){
                // Key is used as header data is renderd as is
                dataRow[setting.header] = data[setting.data];
            }
            csvRows.push(dataRow)
        }

        // available options at: https://github.com/alexcaza/export-to-csv#api
        const csvFileOptions = mkConfig({
            filename: this.title,
            fieldSeparator: ';',
            decimalSeparator: 'locale',
            useKeysAsHeaders: true,
            showColumnHeaders: true,
            useBom: true,
        });

        const csv = generateCsv(csvFileOptions)(csvRows);

        // download or return csv
        if(download){
            download(csvFileOptions)(csv);
        }else{
            return csv
        }
    }

}
