import Block from 'UI/App/Components/Block/Block';
import Button from 'UI/App/Components/Button/Button';
import Icon from 'UI/App/Components/Icon/Icon';
import Grid from 'UI/App/Components/Grid/Grid';
import isEmpty from 'lodash/isEmpty';
import PurchaseQuotePdfEditor from './PurchaseQuotePdfEditor';
import { useContext, useEffect, useState } from 'react';
import { FetchContext } from 'App/Strapi/FetchContext';
import Briefpapier_PRETORE from 'UI/Assets/Images/PdfBackgrounds/Briefpapier_PRETORE.jpg';
import Briefpapier_PRETORE_page_2 from 'UI/Assets/Images/PdfBackgrounds/Briefpapier_PRETORE_2.jpg';
import Briefpapier_HJMG from 'UI/Assets/Images/PdfBackgrounds/Briefpapier_HJMG.jpg';
import Briefpapier_HJMG_page_2 from 'UI/Assets/Images/PdfBackgrounds/Briefpapier_HJMG_2.jpg';
import PurchaseQuoteGenerator from './PurchaseQuoteGenerator';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { AuthContext } from 'App/Strapi/AuthProvider';
import { downloadFile } from 'App/Util/fetch';
import HasRole from 'UI/App/Components/Auth/HasRole';
import { useNavigate, useParams } from 'react-router-dom';
import { stringify } from 'qs';
import { formatName } from 'App/Util/format';
import PageHeader from 'UI/App/Components/PageHeader/PageHeader';

export default function CreatePurchaseQuote() {
    // Default states
    const { authAxios } = useContext(FetchContext);
    const { authState } = useContext(AuthContext);
    const params = useParams();
    const navigate = useNavigate();

    // useStates for quote supplier
    const [language, setLanguage] = useState(null);
    const [type, setType] = useState(null);
    const [suppliersOptions, setSuppliersOptions] = useState([]);
    const [chosenSupplier, setChosenSupplier] = useState(null);
    const [contactPersonOptions, setContactPersonOptions] = useState([]);
    const [chosenContactPerson, setChosenContactPerson] = useState(null);
    const [entryId, setEntryId] = useState(null);
    const [entryNumber, setEntryNumber] = useState(`PUR ${String(new Date().getFullYear()).slice(2)} 0000`);
    const [date, setDate] = useState(new Date().toLocaleDateString('fr-ca'));
    const [signature, setSignature] = useState(null);
    const [order, setOrder] = useState(null);
    const [materialOptions, setMaterialOptions] = useState([])
    const [chosenMaterials, setChosenMaterials] = useState([])

    const [rowData, setRowData] = useState([]);
    const [formattedRows, setFormattedRows] = useState([]);

    // useState for backgrounds
    const [selectedBackground, setSelectedBackground] = useState(null);
    const backgroundSelectOptions = [{
        label: 'Geen',
        value: '',
        pdfTemplate: 1,
        companyName: null,
        owner: null
    },
    {
        label: 'Achtergrond briefpapier PRETORE',
        value: { page1: Briefpapier_PRETORE, page2: Briefpapier_PRETORE_page_2 },
        pdfTemplate: 2,
        companyName: 'Pretore BV',
        owner: 'Pretore'
    },
    {
        label: 'Achtergrond briefpapier HJMG',
        value: { page1: Briefpapier_HJMG, page2: Briefpapier_HJMG_page_2 },
        pdfTemplate: 2,
        companyName: 'HJ Media Groep BV',
        owner: 'HJMG'
    }];

    const [languageOptions] = useState([
        { value: 'nl', label: 'Nederlands' },
        { value: 'en', label: 'Engels' }
    ]);

    const [typeOptions] = useState([
        { value: 'quote', label: 'Offerte' },
        { value: 'purchaseOrder', label: 'Inkooporder' }
    ]);

    useEffect(() => {
        if (params.orderId !== null && params.orderId !== undefined) {
            getOrderData(params.orderId)
        }
    }, [params.orderId]);

    useEffect(() => {
        if (params.purchaseQuoteId === null || params.purchaseQuoteId === undefined) return;
        getPurchaseQuoteData(params.purchaseQuoteId)

    }, [params.purchaseQuoteId, materialOptions.length])

    async function getPurchaseQuoteData(purchaseQuoteId) {
        const query = stringify({

            populate: {
                materials: {
                    populate: {
                        material: true
                    }
                },
                supplier: true,
                contactPerson: true,
                owner: true,
            }
        });
        await authAxios.get(`/calculations/purchases/quotes/${purchaseQuoteId}?${query}`).then(({ data }) => {
            let knowMatIds = data.data.materials.map((el) => el.material.id)
            setChosenMaterials(materialOptions.filter((el) => knowMatIds.includes(el.id)))

            setLanguage(data.data.language)
            setChosenSupplier(data.data.supplier.id)
            setChosenContactPerson(data.data.contactPerson.id)
            setSelectedBackground(backgroundSelectOptions.find(({ owner }) => owner === data?.data?.owner?.name))

            setDate(data.data.date)
            setEntryNumber(data.data.number)
            setFormattedRows(data.data.data)

        }).catch((exception) => {
            toast.error("Kon order niet ophalen");
            console.error(exception);
        });
    }

    async function getOrderData(orderId) {
        await authAxios.get(`/orders/${orderId}?populate=*`).then(({ data }) => {

            if (data.data.calculation?.language?.toLowerCase() === 'nl') {
                setLanguage('nl')
            } else {
                setLanguage('en')
            }
            setOrder(data.data)
            setSelectedBackground(backgroundSelectOptions.find(({ owner }) => owner === 'Pretore'))


            // loadChecklist data to quote rows
            let checklistPage = [];

            for (const checklistItem of data.data.calculation.data.checklist) {

                if (checklistItem.value !== '') {
                    checklistPage.push({
                        label: checklistItem.name,
                        content: checklistItem.value
                    })
                }
            }
            // since checklist can contain unformatted data, set as row data so formatting will take place
            setRowData(checklistPage)

        }).catch((exception) => {
            toast.error("Kon order niet ophalen");
            console.error(exception);
        });
    }

    useEffect(() => {
        setEntryId(params?.number ?? null);
    }, [params?.number]);

    // Load suppliers
    useEffect(() => {
        const query = stringify({
            fields: ['id', 'name'],
            sort: 'name:asc'
        });

        authAxios.get(`/crm/suppliers/all?${query}`).then(({ data }) => {
            let supplierOptions = [];

            for (const supplier of data) {
                supplierOptions.push({
                    label: supplier.name,
                    value: supplier.id
                });
            }

            setSuppliersOptions(supplierOptions);
        });
    }, [authAxios]);

    // Load contact persons from supplier
    useEffect(() => {
        //Reset options on supplier change
        setContactPersonOptions([]);

        if (chosenSupplier) {
            const query = stringify({
                fields: ['id'],
                populate: {
                    contactPersons: {
                        fields: ['firstName', 'prefixToSurname', 'surname']
                    }
                }
            });

            authAxios.get(`/crm/suppliers/${chosenSupplier}?${query}`).then(({ data }) => {
                let contactPersonOptions = [];

                for (const contactPerson of data.data.contactPersons) {
                    contactPersonOptions.push({
                        label: formatName(
                            contactPerson?.firstName,
                            contactPerson?.prefixToSurname,
                            contactPerson?.surname
                        ),
                        value: contactPerson.id
                    });
                }

                setContactPersonOptions(contactPersonOptions);
            });
        }
    }, [authAxios, chosenSupplier]);

    // get authstate user signature image
    useEffect(() => {
        setSignature(false);

        if (authState?.user?.signature?.id) {
            // get the image as a dataURL
            downloadFile(authAxios, authState.user.signature.id, 'base64').then((base64) => {
                setSignature(`data:${authState.user.signature.mime};base64,${base64}`);
            });
        }
    }, [authAxios, authState.user.signature?.id, authState.user.signature?.mime]);

    useEffect(() => {
        const query = stringify({
            filters: {
                active: true
            }
        });

        // get all materials that are active
        authAxios.get(`/logistics/materials/all?${query}`).then(({ data }) => {
            let options = data.map((el) => {
                return {
                    ...el,
                    value: el.id,
                    label: el.quotationNameNL
                }
            })

            setMaterialOptions(options);
        });
    }, []);

    // handle language formating
    useEffect(() => {

        if (rowData === undefined || rowData.length === 0) return;
        // get current 'untranslate' data
        let untranslated = rowData;
        // get the ids of chosenmaterials
        let knowID = chosenMaterials.map((el) => el.id)

        // map the untranslate to a translate version
        let translated = untranslated.map((el) => {
            // check if this entry has an id we want
            if (knowID.includes(el?.id)) {
                // get the the full material
                let wantedMaterial = materialOptions.filter((_el) => _el.id === el.id)[0]

                // check every page
                for (let page of formattedRows) {
                    // check
                    let wantedFormatedRow = page.filter((_el) => _el.id === el.id)[0]
                    let chosenAmount = RegExp(/[0-9,.]+/, 'g').exec(wantedFormatedRow?.label ?? 0)

                    // apply language specfic changes
                    el.label = `${chosenAmount} ${language === "en" ? "copies" : "stuks"}`
                    el.content = `${wantedMaterial?.internalSKU ?? wantedMaterial?.supplierSKU} - ${(language === "en" ? wantedMaterial?.quotationNameEN : wantedMaterial?.quotationNameNL)}`
                    return el
                }
            } else {
                return el
            }
        })
        setRowData(translated)
    }, [language]);

    // handle 'destruction' of materials via pdf editor
    useEffect(() => {
        let knowID = chosenMaterials.map((el) => el.id)
        let totalSumMaterials = []

        // find all the materials over multiple pages
        for (let page of formattedRows) {
            let materials = page.filter((el) => knowID.includes(el.id)).map((el) => el.id)
            totalSumMaterials.push(...materials)
        }

        // if theres a diffrence between the know materials and current materals update chosen materails.
        // this comes in effect if the user deletes an material via the pdf editor
        if (knowID.length > totalSumMaterials.length) {
            let existingRows = []
            // unformat formattedrows
            for (let row of formattedRows) {
                existingRows.push(...row)

            }
            let materialsToKeep = chosenMaterials.filter((el) => totalSumMaterials.includes(el.id))
            let IDtoFilterOn = materialsToKeep.map((el) => el.id)
            // keep all entries that are NOT a product OR have a ID were filtering on
            let productRows = existingRows.filter((el) => el.type !== "product" || IDtoFilterOn.includes(el?.id));

            setRowData(productRows)
            setChosenMaterials(materialsToKeep)
        }

    }, [formattedRows]);

    async function getOwner(name) {
        const query = stringify({
            filters: {
                name: { $eqi: name }
            }
        });

        return await authAxios
            .get(`../owners?${query}`)
            .then(({ data }) => {
                return data.data?.[0]?.id ?? null;
            })
            .catch((error) => {
                console.error(error);
                toast.error('Er is iets fout gegaan bij het ophalen van de inkoopbon eigenaar!');
                return false;
            });
    }

    async function updateSavedQuote() {
        // gaurd agianst missing data
        if (!['en', 'nl'].includes(language)) {
            toast.error('Kies eerst een taal!');
            return;
        } else if (chosenSupplier === null) {
            toast.error('Kies eerst een leverancier!');
            return;
        } else if (chosenContactPerson === null) {
            toast.error('Kies eerst een contact persoon!');
            return;
        } else if (selectedBackground === null) {
            toast.error('Kies eerst een achtergrond!');
            return;
        } else if (date === null) {
            toast.error('Kies eerst een datum!');
            return;
        }

        const ownerId = await getOwner(selectedBackground?.owner);
        if (ownerId === false) {
            return;
        }

        let formatedMaterials = []
        let dateOfRequest = new Date();
        // format chosen materials to fit into the qoute
        for (let material of chosenMaterials) {
            formatedMaterials.push({
                material: material.id,
                dateOfRequest: dateOfRequest,
                type: type
            })
        }

        authAxios
            .put(`/calculations/purchases/quotes/${params.purchaseQuoteId}`, {
                data: {
                    language,
                    data: formattedRows,
                    supplier: chosenSupplier,
                    contactPerson: chosenContactPerson,
                    owner: ownerId,
                    order: params.orderId,
                    materials: formatedMaterials,
                    date
                }
            })
            .then(async ({ data }) => {
                // update created entry with new base64 (pur number included)
                const base64 = await new PurchaseQuoteGenerator(
                    {
                        page1: selectedBackground?.value?.page1,
                        page2: selectedBackground?.value?.page2
                    },
                    formattedRows,
                    chosenSupplier,
                    chosenContactPerson,
                    date,
                    authAxios,
                    false,
                    language,
                    data.data?.number,
                    signature,
                    type
                ).getPDF(formattedRows.length > 0);

                return await authAxios
                    .put(`/calculations/purchases/quotes/${data.data?.id}`, {
                        data: { base64 }
                    })
                    .then(() => {
                        setEntryId(data.data?.id);
                        setEntryNumber(data.data?.number);
                        window.history.pushState(null, '', `/purchase/quotes/${data.data?.id}/edit`);

                        toast.success(`Inkoop offerte opgeslagen!`);
                    })
                    .catch((error) => Promise.reject(error));
            })
            .catch((error) => {
                console.error(error);
                toast.error(`Er ging iets mis met het opslaan van de inkoop offerte.`);
            });
    }

    async function saveNewQuote() {
        // gaurd agianst missing data
        if (!['en', 'nl'].includes(language)) {
            toast.error('Kies eerst een taal!');
            return;
        } else if (chosenSupplier === null) {
            toast.error('Kies eerst een leverancier!');
            return;
        } else if (chosenContactPerson === null) {
            toast.error('Kies eerst een contact persoon!');
            return;
        } else if (selectedBackground === null) {
            toast.error('Kies eerst een achtergrond!');
            return;
        } else if (date === null) {
            toast.error('Kies eerst een datum!');
            return;
        }

        let formatedMaterials = []
        let dateOfRequest = new Date();
        // format chosen materials to fit into the qoute
        for (let material of chosenMaterials) {
            formatedMaterials.push({
                material: material.id,
                dateOfRequest: dateOfRequest,
                type: type
            })
        }

        const ownerId = await getOwner(selectedBackground?.owner);
        if (ownerId === false) {
            return;
        }

        authAxios
            .post(`/calculations/purchases/quotes`, {
                data: {
                    language,
                    data: formattedRows,
                    supplier: chosenSupplier,
                    contactPerson: chosenContactPerson,
                    owner: ownerId,
                    order: params.orderId,
                    materials: formatedMaterials,
                    date
                }
            })
            .then(async ({ data }) => {
                // update created entry with new base64 (pur number included)
                const base64 = await new PurchaseQuoteGenerator(
                    {
                        page1: selectedBackground?.value?.page1,
                        page2: selectedBackground?.value?.page2
                    },
                    formattedRows,
                    chosenSupplier,
                    chosenContactPerson,
                    date,
                    authAxios,
                    false,
                    language,
                    data.data?.number,
                    signature,
                    type,
                ).getPDF(formattedRows.length > 0);

                return await authAxios
                    .put(`/calculations/purchases/quotes/${data.data?.id}`, {
                        data: { base64 }
                    })
                    .then(() => {
                        setEntryId(data.data?.id);
                        setEntryNumber(data.data?.number);
                        window.history.pushState(null, '', `/purchase/quotes/${data.data?.id}/edit`);

                        if (params.orderId) {
                            navigate(`/orders/${params.orderId}`)
                        }

                        toast.success(`Inkoop offerte opgeslagen!`);
                    })
                    .catch((error) => Promise.reject(error));
            })
            .catch((error) => {
                console.error(error);
                toast.error(`Er ging iets mis met het opslaan van de inkoop offerte.`);
            });
    }

    const showPDF = () => {
        // we need a background to show a preview.
        if (selectedBackground === null) {
            toast.error('Kies eerst een achtergrond!');
            return;
        }

        new PurchaseQuoteGenerator(
            {
                page1: selectedBackground?.value?.page1,
                page2: selectedBackground?.value?.page2
            },
            formattedRows ?? null,
            chosenSupplier,
            chosenContactPerson,
            date,
            authAxios,
            true,
            language,
            entryNumber,
            signature,
            type
        ).getPDF(formattedRows.length > 0);
    };

    return (
        <>
            <Grid columns='1' gap='1lh'>
                <PageHeader
                    color='white'
                    title={'Inkoopbon' + (entryNumber ? `: ${entryNumber}` : '')}
                    children={
                        <>
                            <HasRole roles={['admin']}>
                                <Button className='btn btn--icon-right btn--black' onClick={showPDF}>
                                    PDF <Icon name='download' />
                                </Button>
                            </HasRole>

                            <Button
                                className='btn btn--icon-right btn--black'
                                onClick={() => {
                                    params.purchaseQuoteId ? updateSavedQuote() : saveNewQuote();
                                }}
                            >
                                Opslaan <Icon name='save' />
                            </Button>
                        </>
                    }
                />
            </Grid>
            <Grid columns='2' customColTemplate='2fr 5fr'>
                <Block
                    name='stockPurchaseQuote'
                    title={'Instellingen'}
                >

                    <div className='input-group'>
                        <label className='required' htmlFor='type-select'>
                            Type inkoopbon
                        </label>
                        <Select
                            id='type-select'
                            name='type-select'
                            placeholder='Kies een type'
                            options={typeOptions}
                            value={typeOptions.find(({ value }) => value === type) ?? null}
                            onChange={({ value }) => setType(value)}
                        />
                    </div>
                    <div className='input-group'>
                        <label className='required' htmlFor='language-select'>
                            Taal voor de bon
                        </label>
                        <Select
                            id='language-select'
                            name='language-select'
                            placeholder='Kies een taal'
                            options={languageOptions}
                            value={languageOptions.find(({ value }) => value === language) ?? null}
                            onChange={({ value }) => setLanguage(value)}
                        />
                    </div>
                    <div className='input-group'>
                        <label className='required' htmlFor='supplier-select'>
                            Leverancier
                        </label>
                        <Select
                            id='supplier-select'
                            name='supplier-select'
                            placeholder='Kies een leverancier'
                            options={suppliersOptions}
                            value={suppliersOptions.find(({ value }) => value === chosenSupplier) ?? null}
                            onChange={({ value }) => {
                                setChosenSupplier(value);
                                setChosenContactPerson(null);
                            }}
                        />
                    </div>
                    <div className='input-group'>
                        <label className='required' htmlFor='contact-person-select'>
                            Contact persoon
                        </label>
                        <Select
                            id='contact-person-select'
                            name='contact-person-select'
                            placeholder='Kies een contact persoon'
                            options={contactPersonOptions}
                            value={contactPersonOptions.find(({ value }) => value === chosenContactPerson) ?? null}
                            onChange={({ value }) => setChosenContactPerson(value)}
                        />
                    </div>
                    <div className='input-group'>
                        <label htmlFor='pdf-background-select'>Achtergrond afbeelding</label>
                        <Select
                            id='pdf-background-select'
                            name='pdf-background-select'
                            placeholder='Kies een achtergrond afbeelding'
                            options={backgroundSelectOptions}
                            value={selectedBackground}
                            onChange={(e) => {
                                setSelectedBackground(e);
                            }}
                            minMenuHeight={300}
                            formatOptionLabel={({ label, value }) => (
                                <>
                                    {!isEmpty(value) ? (
                                        <div
                                            style={{
                                                backgroundImage: `url(${value.page1})`,
                                                height: '200px',
                                                width: '100%',
                                                backgroundSize: 'cover',
                                                border: '1px dashed black'
                                            }}
                                        />
                                    ) : (
                                        label
                                    )}
                                </>
                            )}
                        />
                    </div>
                    <div className='input-group'>
                        <label htmlFor='pdf-background-select'>Product keuze</label>
                        <Select
                            id='product-select'
                            name='product-select'
                            placeholder='Kies een product'
                            options={materialOptions}
                            isMulti
                            value={chosenMaterials ?? null}
                            onChange={(e) => {
                                if (chosenMaterials.length > e.length) {
                                    let knowID = e.map((el) => el.id)
                                    let productRows = rowData.filter((el) => el.type !== "product" || knowID.includes(el?.id));

                                    setRowData(productRows)
                                } else {
                                    let existingRows = []
                                    // unformat formattedrows
                                    for (let row of formattedRows) {
                                        existingRows.push(...row)

                                    }
                                    let knowID = chosenMaterials.map((el) => el.id)
                                    let productRows = e.filter((el) => !knowID.includes(el?.id));
                                    let formattedMaterials = productRows.map((el) => { return { label: `0 ${language === "en" ? "copies" : "stuks"}`, content: `${el?.internalSKU ?? el?.supplierSKU} - ${(language === "en" ? el.quotationNameEN : el.quotationNameNL)}`, type: "product", id: el.id } })

                                    setRowData([
                                        ...formattedMaterials,
                                        ...existingRows,
                                    ])
                                }

                                setChosenMaterials(e)
                            }}
                        />
                    </div>
                </Block>

                <Grid columns='1'>
                    <PurchaseQuotePdfEditor
                        language={language}
                        selectedBackground={selectedBackground?.value?.page1}
                        selectedBackgroundPage2={selectedBackground?.value?.page2}
                        chosenSupplier={chosenSupplier}
                        chosenContactPerson={chosenContactPerson}
                        date={date}
                        setDate={setDate}
                        rowData={rowData}
                        setRowData={setRowData}
                        number={entryNumber}
                        formattedRows={formattedRows}
                        setFormattedRows={setFormattedRows}
                        signature={signature}
                        type={type}
                        chosenMaterials={chosenMaterials}
                        setChosenMaterials={setChosenMaterials}
                    />
                </Grid>

            </Grid>
        </>
    );
}
