import {useContext, useEffect, useState} from "react"
import {FetchContext} from "App/Strapi/FetchContext"
import { usePagination } from 'UI/App/Components/Pagination/Pagination';
import {stringify} from "qs";
import Table from "UI/App/Components/Table/Table";
import {toast} from "react-toastify";
import {AuthContext} from "App/Strapi/AuthProvider";
import Icon from "UI/App/Components/Icon/Icon";
import Button from "UI/App/Components/Button/Button";
import { BooleanControlled } from "UI/App/Components/Form/Boolean";
import Container from "UI/App/Components/Container/Container";

export default function Users() {
	// Use context for axios call
	const { authAxios } = useContext(FetchContext);
	const [tableData, setTableData] = useState([]);
	const { authState } = useContext(AuthContext);

	// create a state for the table columns (sort order and column field)
	const [tableColumns, setTableColumns] = useState({
		sort: {
			column: 'username',
			direction: 'asc'
		},

		fields: [
			{ name: 'User', field: 'username', sortable: true },
			{ name: 'Email', field: 'email', sortable: false },
			{ name: 'Role', field: 'role', sortable: false },
			{ name: 'Acount Status', field: 'blocked', sortable: true },
			{ name: '', field: '', sortable: false }
		]
	});

	// Enable pagination
	const {
		paging,
		filtering,
		currentPage,
		resultsPerPage,
		setTotalPages,
		setTotalResults,
		filterValues,
		filterQuery,
		paginationStateLoaded,
		setFilterValues
	} = usePagination({
		storageKey: 'crm-users',
		searchSettings: {
			enabled: true,
			strapiFields: [
				'username',
				'email',
				'role.name'
			]
		},
		filters: [
			{
				name: 'Status',
				type: 'select',
				options: [
					{ name: "Alle", value: '*' },
					{ name: "Actief", value: "false" },
					{ name: "Inactief", value: "true" },
				],
				defaultValue: '*',
				strapiFilterFields: ['blocked'],
			},
		],
		buttonCollapseBreakpoint: 1000,
		resultsPerPageBreakpoint: 1960,
		paginationBreakpoint: 1270,
		resultCountBreakpoint: 1850
	});

	useEffect(() => {
		if (!paginationStateLoaded) return;

		// build the filter parameters for this query
		const query = buildSearchParams(resultsPerPage, currentPage, tableColumns);

		authAxios.get(`../users/limited?${query}&${filterQuery}`).then(({ data: { data, meta } }) => {
			setTotalPages(meta.pagination.pageCount)
			setTotalResults(meta.pagination.total)

			const tempData = [];

			for (let user of data) {
				tempData.push({
					attributes: {
						user: user.id,
					},
					data: [
						user.username,
						user.email,
						user.roleName,
						<BooleanControlled
							leftLabel='Inactief'
							rightLabel='Actief'
							leftIsRed={true}
							value={!user.blocked}
							field={{ name: 'active' }}
							falseIsRed={true}
							setValue={(value) => {
								if (!user.blocked === value) return;
								toggleBlocked(!user.blocked, user.id)
							}}
						/>,
						<div className={'table__action-buttons'}>
							<Button data-title={'Bewerken'} className='btn btn--transparent' to={`/admin/users/${user.id}`}><Icon name='pencil' /></Button>
						</div>
					]
				})
			};

			setTableData(tempData);
		}).catch((exception) => {
			toast.error(`Er is iets fout gegaan ${exception?.response?.status ? `(${exception.response?.status})` : ''}`)

			console.error(exception);
		});



	}, [
		authAxios,
		currentPage,
		resultsPerPage,
		authAxios,
		setTotalPages,
		setTotalResults,
		filterQuery,
		tableColumns,
		paginationStateLoaded,
		authState.user.id
	]
	);


	function toggleBlocked(blocked, userId) {
		authAxios.put(`../users/${userId}`, {
			blocked: blocked
		}).then(() => {
			setTableData(prevData => {
				return prevData.map((row) => {
					if (row.attributes.user === userId) {
						row.data[3] = < BooleanControlled
							leftLabel='Inactief'
							rightLabel='Actief'
							leftIsRed={true}
							value={!blocked}
							nullable={false}
							field={{ name: 'active' }}
							falseIsRed={true}
							setValue={(value) => {
								if (blocked === value) return;
								toggleBlocked(!blocked, userId)
							}}
						/>
					}
					return row;
				})
			})

			toast.success('Gebruiker bijgewerkt!');
		});
	}

	function buildSearchParams(resultsPerPage, currentPage, tableColumns) {

		let queryParams = {
			pagination: {
				page: currentPage,
				pageSize: resultsPerPage
			},
			populate: {
				role: {
					populate: {
						name: true
					}
				}
			},
			sort: { [tableColumns.sort.column]: tableColumns.sort.direction }
		};

		const query = stringify(queryParams, {
			encodeValuesOnly: true // prettify URL
		});

		return query;
	}

	return (
		<Container>
			{filtering}
			<Table
				headers={['User', 'Email', 'Role', 'Account status', '']}
				data={tableData}
				tableColumns={tableColumns}
				setTableColumns={setTableColumns}
				structure={['50%', '50%', '150px', '200px', '70px']}
				hover={false}
				padding={'m'}
				columnOverflowExceptions={[4]}
			/>
			{paging}
		</Container>
	)
}
