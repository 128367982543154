import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { stringify } from 'qs';
import { FetchContext } from 'App/Strapi/FetchContext';
import { AuthContext } from 'App/Strapi/AuthProvider';
import { downloadFile } from 'App/Util/fetch';
import { formatDateWithoutTime } from 'App/Util/format';
import openFileInNewTab from 'App/Util/openFileInNewTab';
import ToggleSwitch from 'UI/App/Components/Form/ToggleSwitch/ToggleSwitch';
import Grid from 'UI/App/Components/Grid/Grid';
import Icon from 'UI/App/Components/Icon/Icon';
import Popup, { closePopup } from 'UI/App/Components/Popup/Popup';
import { memosQuery } from 'UI/App/Partials/Content/Dashboard/Blocks/Memos/Memos';

const usersQuery = stringify({
    fields: ['id', 'username'],
    filters: {
        blocked: false
    },
    sort: ['username:asc']
});

// the popup form default state values.
// (Object.freeze doesn't fix references changing without destructing)
const memoDefaultValues = {
    title: '',
    datetime: {
        date: '',
        wholeDay: true,
        startTime: '',
        endTime: ''
    },
    recipients: [],
    content: '',
    attachment: {
        id: null,
        blobURL: null,
        mime: null,
        newFile: null,
        canEdit: true
    },
    linkedItem: undefined
};

export default function PopupAddOrEditMemo({ setMemos, editStateIndex, editId, changedFormValues }) {
    const { authState } = useContext(AuthContext);
    const { authAxios } = useContext(FetchContext);

    const attachmentRef = useRef(null);

    // state for the users checkboxes
    const [userOptions, setUserOptions] = useState([]);

    // popup form state
    // ! destruct to prevent references overriding
    // ! destruct does shallow copy so reset the objects itself too
    const [memoValues, setMemoValues] = useState({
        ...memoDefaultValues,
        datetime: { ...memoDefaultValues.datetime },
        attachment: []
    });

    const colourStyles = {
        control: (styles) => ({ ...styles, backgroundColor: 'white' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            const color = data.color;

            return {
                ...styles,
                borderLeft: `8px ${color} solid`
            };
        }
    };

    const importanceOptions = [
        { label: 'Laag', value: 'low', color: 'lightgreen' },
        { label: 'Normaal', value: 'normal', color: 'orange' },
        { label: 'Hoog', value: 'high', color: 'red' }
    ];

    // get all users for the recipient options
    useEffect(() => {
        authAxios.get(`../users?${usersQuery}`).then(({ data }) => {
            // add users to the options state
            setUserOptions(() => data.map((user) => ({ value: user.id, label: user.username })));
        });
    }, [authAxios]);

    // reset values to default with correct content, startDate and default recipients
    useEffect(() => {
        setMemoValues(() => {
            // add all default values first.
            // ! destruct to prevent references overriding
            // ! destruct does shallow copy so reset the objects itself too
            const resetValues = {
                ...memoDefaultValues,
                datetime: { ...memoDefaultValues.datetime },
                attachment: []
            };

            // change start date to today as default
            resetValues.datetime.date = formatDateWithoutTime(null, undefined, 'sv-SE');

            // change recipients to the current user as selected value
            resetValues.recipients = [{ value: authState.user.id, label: authState.user.username }];

            // return the reset values with the changed ones from the prop
            return { ...resetValues, ...changedFormValues };
        });
    }, [authState.user.id, authState.user.username, changedFormValues]);

    // on emptying attachment blobURL through file input cancel or remove button
    useEffect(() => {
        if (attachmentRef.current && !memoValues.attachment?.newFile) {
            // file input value can only be programmatically set to empty string to clear the input's text
            attachmentRef.current.value = '';
        }
    }, [memoValues.attachment?.newFile]);

    const saveMemo = useCallback(
        async (e) => {
            e.preventDefault();

            // if theres not at least one memo__attendees checkbox checked, return and give toast error
            if ((memoValues?.recipients?.length ?? 0) === 0) {
                toast.error('Selecteer minimaal 1 ontvanger.');
                return;
            }

            // create formData object for files
            const formData = new FormData();

            // the memo post object. ! assign destructed to prevent reference breaking the state
            let memo = { ...memoValues };

            // change times if whole day is checked
            if (memo.datetime.wholeDay) {
                memo.datetime.startTime = null;
                memo.datetime.endTime = null;
            } else {
                // add required seconds + miliseconds. use regex to prevent double ':00.000:00.000'
                memo.datetime.startTime = memo.datetime.startTime.replace(/^(\d{2}:\d{2}).*?$/, `$1:00.000`);
                memo.datetime.endTime = memo.datetime.endTime.replace(/^(\d{2}:\d{2}).*?$/, `$1:00.000`);
            }

            // set recipients to just userId's
            memo.recipients = memo.recipients.map(({ value }) => value);

            // prep attachments 
            if (memo?.attachment?.length > 0) {
                let files = []
                for (let file of memo?.attachment) {
                    if (file.id) {
                        file = file.id
                        files.push({ file })
                    } else if (file.newFile) {
                        formData.append('files.attachment', file.newFile, file.newFile.name);

                    } else {
                        file = null
                        files.push(file)
                    }

                }
                memo.attachment = files
            }


            // add the memo data to the formData
            formData.append('data', JSON.stringify(memo));

            if (editId) {
                // send update request
                await authAxios
                    .put(`memos/${editId}?${memosQuery}`, formData)
                    .then(async ({ data: { data: updatedMemo } }) => {
                        // update the memo at the index in the state
                        if (editStateIndex !== null && typeof setMemos === 'function') {
                            // change recipients to react-select format
                            updatedMemo.recipients = updatedMemo.recipients.map((user) => ({
                                value: user.id,
                                label: user.username
                            }));

                            // add thumbnail blob
                            if (updatedMemo.attachment) {
                                for (let file of updatedMemo.attachment) {
                                    if (file?.formats?.thumbnail?.url) {
                                        file.formats.thumbnail.blob = await downloadFile(
                                            authAxios,
                                            file?.id,
                                            file.formats.thumbnail?.mime,
                                            'thumbnail'
                                        ).catch(() => null);
                                    } else {
                                        file.blob = await downloadFile(
                                            authAxios,
                                            file?.id,
                                            file?.mime
                                        ).catch(() => null);
                                    }
                                }

                            }

                            setMemos((memos) => {
                                memos[editStateIndex] = updatedMemo;
                                return [...memos];
                            });
                        }

                        toast.success('De memo is succevol bijgewerkt.');
                        closePopup();
                    })
                    .catch((error) => {
                        console.error(error);
                        toast.error('Er is een probleem opgetreden bij het bijwerken van de memo!');
                    });
            } else {
                // send post request
                await authAxios
                    .post(`memos?${memosQuery}`, formData)
                    .then(async ({ data: { data: newMemo } }) => {
                        // add the memo to first index of the state, even if the list becomes unordered
                        if (typeof setMemos === 'function') {
                            // change recipients to react-select format
                            newMemo.recipients = newMemo.recipients.map((user) => ({
                                value: user.id,
                                label: user.username
                            }));

                            // add thumbnail blob
                            if (newMemo.attachment) {
                                if (newMemo.attachment?.formats?.thumbnail?.url) {
                                    newMemo.attachment.formats.thumbnail.blob = await downloadFile(
                                        authAxios,
                                        newMemo.attachment?.id,
                                        newMemo.attachment.formats.thumbnail?.mime,
                                        'thumbnail'
                                    ).catch(() => null);
                                } else {
                                    newMemo.attachment.blob = await downloadFile(
                                        authAxios,
                                        newMemo.attachment?.id,
                                        newMemo.attachment?.mime
                                    ).catch(() => null);
                                }
                            }

                            setMemos((memos) => [newMemo, ...memos]);
                        }

                        toast.success('De memo is succevol aangemaakt.');
                        closePopup();
                    })
                    .catch((error) => {
                        console.error(error);
                        toast.error('Er is een probleem opgetreden bij het aanmaken van de memo!');
                    });
            }
        },
        [authAxios, editId, editStateIndex, memoValues, setMemos]
    );

    return (
        <Popup title={`Memo ${editId ? 'bijwerken' : 'aanmaken'}`} popupID='add-or-edit-memo'>
            <form onSubmit={saveMemo}>
                {/* title */}
                <div className='input-group'>
                    <label className='required' htmlFor='memo__title'>
                        Memo titel
                    </label>
                    <input
                        type='text'
                        id='memo__title'
                        name='memo__title'
                        placeholder='Titel'
                        value={memoValues.title}
                        onChange={({ target }) => setMemoValues((memo) => ({ ...memo, title: target.value }))}
                        onBlur={({ target }) =>
                            setMemoValues((memo) => ({ ...memo, title: (target.value ?? '').trim() }))
                        }
                        required
                    />
                </div>

                {/* date */}
                <div className='input-group'>
                    <label className='required' htmlFor='memo__datetime__Date'>
                        Memo datum
                    </label>
                    <input
                        type='date'
                        id='memo__datetime__Date'
                        name='memo__datetime__Date'
                        value={memoValues.datetime.date}
                        onChange={({ target }) =>
                            setMemoValues((memo) => {
                                memo.datetime.date = target.value;
                                return { ...memo };
                            })
                        }
                        min={formatDateWithoutTime(null, undefined, 'sv-SE')}
                        required
                    />
                </div>

                {/* entire day */}
                <div className='input-group' style={{ display: 'flex', flexDirection: 'column' }}>
                    <label htmlFor='memo__datetime__whole-day'>Memo voor de hele dag</label>
                    <ToggleSwitch
                        attributes={{ id: 'memo__datetime__whole-day', name: 'memo__datetime__whole-day' }}
                        checked={memoValues.datetime.wholeDay}
                        onChange={({ target }) => {
                            setMemoValues((memo) => {
                                memo.datetime.wholeDay = target.checked;
                                return { ...memo };
                            });
                        }}
                    />
                </div>

                {/* entire day times */}
                <Grid
                    gap='var(--gap-s)'
                    columns='2'
                    style={{ display: memoValues.datetime.wholeDay ? 'none' : undefined }}
                >
                    {/* startTime */}
                    <div className='input-group'>
                        <label className='required' htmlFor='memo__datetime__start-time'>
                            Start tijd
                        </label>
                        <input
                            type='time'
                            id='memo__datetime__start-time'
                            name='memo__datetime__start-time'
                            value={memoValues.datetime.startTime ?? ''} // value can be `null` so coalesce into `''`
                            onChange={({ target }) =>
                                setMemoValues((memo) => {
                                    memo.datetime.startTime = target.value;
                                    return { ...memo };
                                })
                            }
                            max={memoValues.datetime.endTime}
                            required={!memoValues.datetime.wholeDay}
                        />
                    </div>

                    {/* endTime */}
                    <div className='input-group'>
                        <label className='required' htmlFor='memo__datetime__end-time'>
                            Eind tijd
                        </label>
                        <input
                            type='time'
                            id='memo__datetime__end-time'
                            name='memo__datetime__end-time'
                            value={memoValues.datetime.endTime ?? ''} // value can be `null` so coalesce into `''`
                            onChange={({ target }) =>
                                setMemoValues((memo) => {
                                    memo.datetime.endTime = target.value;
                                    return { ...memo };
                                })
                            }
                            min={memoValues.datetime.startTime}
                            required={!memoValues.datetime.wholeDay}
                        />
                    </div>
                </Grid>

                {/* recipients */}
                <div className='input-group'>
                    <label className='required' htmlFor='memo__recipients'>
                        Ontvangers:
                    </label>
                    <Select
                        name='memo__recipients'
                        id='memo__recipients'
                        placeholder='Selecteer alle werknemers waarin u deze memo voor wil aanmaken'
                        options={userOptions}
                        value={memoValues.recipients}
                        onChange={(recipients) => setMemoValues((memo) => ({ ...memo, recipients }))}
                        isMulti
                    />
                </div>

                {/* content */}
                <div className='input-group '>
                    <label className='required' htmlFor='memo__content'>
                        Omschrijving
                    </label>
                    <textarea
                        id='memo__content'
                        name='memo__content'
                        placeholder='Memo beschrijving'
                        value={memoValues.content}
                        onChange={({ target }) => setMemoValues((memo) => ({ ...memo, content: target.value }))}
                        onBlur={({ target }) =>
                            setMemoValues((memo) => ({ ...memo, content: (target.value ?? '').trim() }))
                        }
                        required
                        rows='6'
                    />
                </div>
                <div className='input-group '>
                    <label htmlFor='memo__importance'>Prioriteit</label>
                    <Select
                        id='memo_importance'
                        name='memo_importance'
                        placeholder='selecteer de prioriteit van deze memo.'
                        value={importanceOptions.filter((entry) => entry.value === memoValues.importance)}
                        options={importanceOptions}
                        styles={colourStyles}
                        onChange={(priority) => setMemoValues((memo) => ({ ...memo, importance: priority.value }))}
                    />
                </div>

                <div className='input-group'>
                    <label htmlFor='memo__attachment'>Bijlage</label>
                    <div className='file-upload'>

                        {memoValues?.attachment?.length > 0 &&

                            memoValues?.attachment?.map(file => {

                                return (<>
                                    <div
                                        className={`file-upload__preview__container ${!/^image|video/.test(file?.mime) ? 'auto-size' : ''
                                            }`}
                                    >
                                        {/* show preview image */}
                                        {/^image/.test(file?.mime) && (
                                            <div
                                                className='file-upload__preview__wrapper'
                                                onClick={() => {
                                                    if (file?.newFile) {
                                                        openFileInNewTab(file?.blobURL);
                                                    } else {
                                                        openFileInNewTab(
                                                            undefined,
                                                            authAxios,
                                                            file?.id,
                                                            file?.mime
                                                        );
                                                    }
                                                }}
                                            >
                                                <div className='overflow-hidden--center'>
                                                    <img
                                                        src={file?.blobURL}
                                                        alt={'memo attachment'}
                                                        className='file-upload__preview-img'
                                                    />
                                                </div>
                                            </div>
                                        )}

                                        {/* show preview video */}
                                        {/^video/.test(file?.mime) && (
                                            <div
                                                className='file-upload__preview__wrapper'
                                                onClick={() => openFileInNewTab(file?.blobURL)}
                                            >
                                                <div className='video__wrapper overflow-hidden--center'>
                                                    <video controls={false}>
                                                        <source
                                                            src={file?.blobURL}
                                                            type={file?.mime}
                                                        />
                                                    </video>
                                                    <div className='video__overlay'>
                                                        <Icon name='circle-play' width='80px' />
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {/* show icon for other files */}
                                        {!/^image|video/.test(file?.mime) && (
                                            <div
                                                className='file-upload__preview-icon__wrapper'
                                                onClick={() =>
                                                    openFileInNewTab(openFileInNewTab(file?.blobURL))
                                                }
                                            >
                                                <Icon
                                                    name='file-paperclip'
                                                    width='50px'
                                                    className='file-upload__preview-img'
                                                />
                                            </div>
                                        )}

                                        {/* show remove button */}
                                        {file?.canEdit && (
                                            <button
                                                type='button'
                                                className='file-upload__delete-btn btn btn--transparent btn--icon-red'
                                                onClick={() => {
                                                    setMemoValues((memo) => {
                                                        memo.attachment = memo.attachment.filter(el => el.id !== file.id)
                                                        // destruct to prevent references overriding
                                                        file = { ...memoDefaultValues.attachment };

                                                        return { ...memo };
                                                    });
                                                }}
                                                title='verwijder bijlage'
                                                form=''
                                            >
                                                <Icon name='close' width='30px' />
                                            </button>
                                        )}
                                    </div>
                                </>);

                            })
                        }

                        {(memoValues?.attachment?.length > 0 && memoValues?.attachment?.some(el => el.canEdit === true) || (memoValues?.attachment?.length === 0)) && (
                            <input
                                type='file'
                                multiple
                                ref={attachmentRef}
                                onChange={({ target }) => {
                                    let fileArray = [];
                                    for (let file of target.files) {
                                        if (file) {

                                            // create default object
                                            let fileObject = { ...memoDefaultValues.attachment };

                                            // file object with data
                                            fileObject.blobURL = window.URL.createObjectURL(file, {
                                                type: file.type
                                            });
                                            fileObject.mime = file.type;
                                            fileObject.newFile = file;

                                            // store the file in an array
                                            fileArray.push(fileObject);
                                        }
                                    }
                                    if (fileArray.length > 0) {

                                        setMemoValues((memo) => {

                                            memo.attachment = memo?.attachment?.length > 0 ? memo?.attachment?.concat(fileArray) : fileArray;
                                            return { ...memo };
                                        })
                                    }

                                }}
                            />
                        )}

                        {(memoValues?.attachment?.length > 0 && !memoValues?.attachment?.some(el => el.canEdit === true)) && (
                            <span className='file-upload__no-edit-message'>
                                De bijlage kan niet worden veranderd voor deze memo
                            </span>
                        )}
                    </div>
                </div>

                {/* submit */}
                <div className='input-group'>
                    <button type='submit'>
                        Opslaan <Icon name='save' />
                    </button>
                </div>
            </form>
        </Popup>
    );
}
