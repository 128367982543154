import React from "react";
import PDF from "../../../../../../App/Util/print";
import {getNumLines} from "App/Util/getVisibleTextWidth";
import {mmToPx} from "App/Util/format";
import {formatDataForPDF} from "App/Util/pdf";

export default class PurchaseQuoteGenerator extends React.Component {
    background;
    rowData;
    chosenSupplier;
    chosenContactPerson;
    authAxios;
    userinfo;
    date;
    showPdf;
    language;
    purNumber;
    signature;
    quoteType;

    constructor(selectedBackground, rowData, chosenSupplier, chosenContactPerson, date, authAxios, showPdf = false, language = 'nl', purNumber = null, signature, quoteType) {
        super();
        this.background = selectedBackground;
        this.rowData = rowData
        this.chosenSupplier = chosenSupplier;
        this.chosenContactPerson = chosenContactPerson;
        this.authAxios = authAxios;
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.date = date ?? new Date().toLocaleDateString('fr-ca');
        this.showPdf = showPdf;
        this.language = language;
        this.purNumber = purNumber;
        this.signature = signature;
        this.quoteType = quoteType;
    }

    getOffsetByType(type) {
        switch (type) {
            case 'textarea':
                return 5

            default:
                return 1
        }
    }
    async formatForPdf(data, preformatted = false) {
        // I hate this, but it would take too long to fix
        const supplier = this.chosenSupplier ?
            await this.authAxios
                .get(`/crm/suppliers/${this.chosenSupplier}?populate=address&fields[]=name`)
                .then(({ data }) => {
                    return data.data;
                })
                .catch((error) => {
                    console.error(error);
                })
            : {};

        return formatDataForPDF({
            data: data,
            company: supplier,
            contactPerson: this.chosenContactPerson,
            isDataPreFormatted: preformatted,
            signature: this.signature,
            headerRowCount: 11,
            footerRowCount: 6,
            language: this.language,
            preserveData: true,
        });
    }

    async getPDF(preformatted = false) {

        // let lineHeight = 3.852;
        let lineHeight = 4;
        let currentlineHeight = 50;
        let paddingLeft = 20;
        let pageCutoffXCords = 295;
        let fontSize = 10;

        // diffrent texts per type
        let typeTitleNL = this.quoteType === "quote" ? "OFFERTE AANVRAAG" : "INKOOP ORDER";
        let typeTitleEN = this.quoteType === "quote" ? "REQEUST FOR QUOTATION" : "PURCHASE ORDER"
        let typeQueryEN = this.quoteType === "quote" ? "We would like to request the following from you:" : "We would like to order the following from you:";
        let typeQueryNL = this.quoteType === "quote" ? "Graag willen we een offerte aanvragen voor het onderstaande:" : "Graag willen we het volgende bij je bestellen:";

        let contactPerson = null// Add Contact person
        if (this.chosenContactPerson) {
            await this.authAxios
                .get(`/crm/suppliers/persons/${this.chosenContactPerson}?populate=*`)
                .then(({ data }) => {
                    contactPerson = data.data;
                });
        }

        /**
         * Add background
         */
        var doc = new PDF();
        if (this.background?.page1) {
            doc.addImage(this.background.page1, 'JPEG', 0, 0, 210, 297);
        }

        doc.setFont('Helvetica', 'normal');
        // Add title
        doc.setFontStyle('bold');
        doc.setFontSize(fontSize);

        doc.text(this.language === 'nl' ? typeTitleNL : typeTitleEN, paddingLeft, currentlineHeight);
        currentlineHeight += (lineHeight * 2);
        doc.setFont('Helvetica', 'normal');

        // Add supplier
        if (this.chosenSupplier) {
            await this.authAxios
                .get(`/crm/suppliers/${this.chosenSupplier}?populate=*`)
                .then(({ data }) => {
                    // Add title
                    // supplier name
                    if (data.data?.name) {
                        doc.text(data.data?.name, paddingLeft, currentlineHeight);
                        currentlineHeight += lineHeight;
                    }

                    if (contactPerson !== null) {
                        doc.text(`${contactPerson.firstName}${contactPerson.prefixToSurname !== null ? ` ${contactPerson.prefixToSurname.trim()} ` : ' '}${contactPerson.surname}`, paddingLeft, currentlineHeight);
                        currentlineHeight += lineHeight;
                    }


                    // supplier addressLine1
                    if (data.data?.address?.addressLine1 !== "") {
                        doc.text(data.data?.address?.addressLine1, paddingLeft, currentlineHeight);
                        currentlineHeight += lineHeight;
                    }

                    // supplier addressLine2
                    if (data.data?.address?.addressLine2 !== "") {
                        doc.text(data.data?.address?.addressLine2, paddingLeft, currentlineHeight);
                        currentlineHeight += lineHeight;
                    }

                    // supplier addressLine3
                    if (data.data?.address?.addressLine3 !== "") {
                        doc.text(data.data?.address?.addressLine3, paddingLeft, currentlineHeight);
                        currentlineHeight += lineHeight;
                    }

                    // supplier postal & city
                    if (data.data?.address?.postalCode || data.data?.address?.city) {
                        // double space is in original document
                        doc.text(
                            `${data.data?.address?.postalCode ?? ''}  ${data.data?.address?.city ?? ''
                                }`
                                .trim()
                                .toUpperCase(),
                            paddingLeft,
                            currentlineHeight
                        );
                        currentlineHeight += lineHeight;
                    }

                    // supplier country
                    if (data.data?.address?.country !== "") {
                        doc.text(data.data?.address?.country, paddingLeft, currentlineHeight);
                        currentlineHeight += lineHeight;
                    }
                });
        }

        currentlineHeight += (lineHeight * 3);

        let formattedDate = '';
        if (this.date !== null) {
            const options = { year: 'numeric', month: 'long', day: 'numeric' };
            const locale = this.language === 'nl' ? 'nl-NL' : 'en-US';
            formattedDate = (new Date(this.date)).toLocaleDateString(locale, options)
        }


        // the date
        if (this.background?.page1.includes('Briefpapier_HJMG')){
            doc.text(
                `Ridderkerk, ${formattedDate}`,
                paddingLeft,
                currentlineHeight
            );
        } else {
            doc.text(
                `Dordrecht, ${formattedDate}`,
                paddingLeft,
                currentlineHeight
            );
        } 
        
        currentlineHeight += (lineHeight * 3);

        // pur number
        if (this.purNumber !== null) {
            doc.text(this.language === 'nl' ? 'Ons inkoopnummer' : 'Our reference', paddingLeft, currentlineHeight)
            doc.text(`:`, paddingLeft + 45, currentlineHeight);
            doc.text(this.purNumber, paddingLeft + 50, currentlineHeight, {
                maxWidth: 120
            });

            currentlineHeight += (lineHeight * 3);
        }

        // Add Contact person
        if (this.chosenContactPerson) {
            await this.authAxios
                .get(`/crm/suppliers/persons/${this.chosenContactPerson}?populate=*`)
                .then(({ data }) => {
                    doc.text(`${this.language === 'nl' ? 'Beste' : 'Dear'} ${data.data.firstName},`, paddingLeft, currentlineHeight);
                    currentlineHeight += (lineHeight * 2);
                });
        }

        doc.text(
            this.language === 'nl' ? typeQueryNL : typeQueryEN,
            paddingLeft,
            currentlineHeight
        );
        currentlineHeight += (lineHeight * 2);


        let formattedRows;
        if (preformatted) {
            formattedRows = this.rowData
        } else {
            formattedRows = await this.formatForPdf(this.rowData, false)
        }

        // Add data rows
        let currentPage = 0;
        if (formattedRows.length !== 0) {
            for (const rows of formattedRows) {
                //Add page after page 2
                if (currentPage !== 0) {
                    currentlineHeight = 50;
                    doc.addPage()
                    if (this.background?.page2) {
                        doc.addImage(this.background?.page2, 'JPEG', 0, 0, 210, 297);
                    }
                }

                for (const row of rows) {
                    // Add a new page if the row doesn't fit properly on the current page
                    const totalRowHeight = getNumLines(row.content, '10pt helvetica', mmToPx(120)) * lineHeight;
                    // currentlineHeight = doc.addNewPageIfOverflown(currentlineHeight, 15 + totalRowHeight, 20);
                    if (row?.type === undefined || row?.type === null) {
                        //First part of row
                        doc.text((row.label ?? (this.language === 'nl' ? row.dutchLabel : row.englishLabel) ?? row.defaultLabel), paddingLeft, currentlineHeight);

                        // Only add spacer if label is set
                        if ((row.label ?? (this.language === 'nl' ? row.dutchLabel : row.englishLabel) ?? row.defaultLabel) !== '') {
                            // Add spacer
                            doc.text(`:`, paddingLeft + 45, currentlineHeight);
                        }

                        // Add value
                        doc.text(row.content, paddingLeft + 50, currentlineHeight, {
                            maxWidth: 120
                        });

                        currentlineHeight += totalRowHeight;

                        currentlineHeight += 1; 
                    } else if (row.type === 'textarea') {
                        doc.text(row.content, paddingLeft, currentlineHeight, {
                            maxWidth: 120
                        });

                        currentlineHeight += totalRowHeight;

                        currentlineHeight += 1;
                    } else { // default row, if type is given but not handeld
                        //First part of row
                        doc.text((row.label ?? (this.language === 'nl' ? row.dutchLabel : row.englishLabel) ?? row.defaultLabel), paddingLeft, currentlineHeight);

                        // Only add spacer if label is set
                        if ((row.label ?? (this.language === 'nl' ? row.dutchLabel : row.englishLabel) ?? row.defaultLabel) !== '') {
                            // Add spacer
                            doc.text(`:`, paddingLeft + 45, currentlineHeight);
                        }

                        // Add value
                        doc.text(row.content, paddingLeft + 50, currentlineHeight, {
                            maxWidth: 120
                        });

                        currentlineHeight += totalRowHeight;

                        currentlineHeight += 1;
                    }
                }
                currentPage++;
            }
        }

        currentlineHeight += lineHeight;

        // currentlineHeight = doc.addNewPageIfOverflown(currentlineHeight, 50, 20);

        doc.text(
            this.language === 'nl' ? 'Bij eventuele vragen of opmerkingen kunt u ons altijd bellen of mailen.' : `If there are any questions, we would like to hear from you!`,
            paddingLeft,
            currentlineHeight
        );

        currentlineHeight += (lineHeight * 2);

        doc.text(
            this.language === 'nl' ? 'Met vriendelijke groet,' : `Kind regards,`,
            paddingLeft,
            currentlineHeight
        );
        currentlineHeight += lineHeight;

        
        if (this.background?.page1.indexOf('Briefpapier_HJMG') > 0){
            doc.text(
                'HJ Media Groep B.V.',
                paddingLeft,
                currentlineHeight
            );
        } else {
            doc.text(
                'Pretore B.V.',
                paddingLeft,
                currentlineHeight
            );
        }   

        currentlineHeight += lineHeight * 2;

        if (this.signature) {
            const prevlineHeight = currentlineHeight;

            const imageHeight = lineHeight * 4;

            // calculate if section needs to go to the next page
            const currentLineYPosisition = doc.addNewPageIfOverflown(currentlineHeight + imageHeight, 21, 50, this.background?.page2);
            currentlineHeight =
                currentLineYPosisition > prevlineHeight ? prevlineHeight : currentLineYPosisition;

            // add the image to the correct lineHeight
            doc.addImage(this.signature, undefined, paddingLeft, currentlineHeight, 0, imageHeight);

            // set the currentlineHeight to below the image
            currentlineHeight += imageHeight + lineHeight;
        }

        currentlineHeight += lineHeight;

        if (this.userInfo !== undefined) {
            doc.text(
                `${this.userInfo.firstName} ${this.userInfo.lastName}`,
                paddingLeft,
                currentlineHeight
            );
        }

        if (this.showPdf) {
            doc.openPDF()
        }

        return window.btoa(doc.output());
    }

    getPdfStringFromHtml(row, doc, defaultFontSize = 10, rawRow = null) {
        doc.setFont('Helvetica', 'normal');

        row.replace(/\&nbsp;/g, '');
        doc.setFontSize(defaultFontSize);
        doc.setTextColor(0, 0, 0);

        if (rawRow !== null) {
            if (rawRow === 'h1') {
                doc.setFont('Helvetica', 'bold');
                doc.setFontSize(defaultFontSize + 6);
            } else if (rawRow === 'h2') {
                doc.setFont('Helvetica', 'bold');
                doc.setFontSize(defaultFontSize + 4);
            } else if (rawRow === 'h3') {
                doc.setFont('Helvetica', 'bold');
                doc.setFontSize(defaultFontSize + 2);
            }
        }

        // Replace HTML chars
        row.replaceAll('&amp;', '&')

        /**
         * Horizontal line
         */
        if (row.match(/━━━━━━━━━━━━━━━━━━/)) {
            row = row = '[LINE]'
        }

        /**
         * Remove unwanted spans
         */
        if (row.match(/<span(.*?)<\/span>/gi)) {
            if (row.match(/color: rgb\((.*?)\)/gi)) {
                const colorArray = row.match(/color: rgb\((.*?)\)/i)[1].replace(' ', '').split(',');
                doc.setTextColor(parseInt(colorArray[0]), parseInt(colorArray[1]), parseInt(colorArray[2]));
                row = row.replace(/<span(.*?)>/gi, '')
                row = row.replace(/<\/span>/gi, '')
            } else {
                row = row.replace(/<span(.*?)<\/span>/gi, '')
            }
        }

        if (row.match(/(<strong>)(.*?)(<\/strong>)/gi)) {
            /**
             * Remove tags from row
             */
            row = row.replace('<strong>', '').replace('</strong>', '')
            doc.setFont('Helvetica', 'bold');
        }

        // Find <p></p>
        if (row.match(/(<p>)(.*?)(<\/p>)/gi)) {
            /**
             * Remove tags from row
             */
            row = row.replace('<p>', '').replace('</p>', '')
        }

        // Find <br>
        if (row.match(/<br>/gi)) {
            /**
             * Remove tags from row
             */
            row = row.replace('<br>', '');
        }


        if (row.match(/^([^\t]*)(\t{3,})([^\t]+)(\t{3,})([^\t]*)$/gmi)) {
            let values = [];

            for (const match of row.replace(/&nbsp;/g, '').split(/\t/)) {
                if (match !== '') {
                    values.push(match);
                }
            }

            row = [];
            row[5] = values[0];
            row[6] = values[1];
            row[7] = values[2];
        } else {
            if (this.numberOfTabs(row) >= 1 && this.numberOfTabs(row) <= 15) {
                const noTabs = this.numberOfTabs(row);
                let splitRow = row.replace(/&nbsp;/g, '').split(/\t/);
                const begin = splitRow[0];
                const end = splitRow[splitRow.length - 1]
                let tabs = ''

                for (let i = 0; i < noTabs; i++) {
                    tabs += '\t'
                }

                row = [];
                row[0] = begin.replace(/\t/, '')
                row[1] = end.replace(/\t/, '')
            } else if (this.numberOfTabs(row) > 15) {
                let splitRow = row.replace(/&nbsp;/g, '').split(/\t/);
                const begin = splitRow[0];
                const end = splitRow[splitRow.length - 1];

                row = [];
                row[0] = begin.replace(/\t/, '')
                if (!this.isOrder) {
                    row[2] = end.replace('€', '').replace(/\t/, '')
                } else {
                    row[1] = end
                }
            }
        }


        return row;
    }

    decodeHTMLEntities(str) {
        if (str && typeof str === 'string') {
            // strip script/html tags
            str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
            str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
        }

        return str;
    }


    numberOfTabs(text) {
        return (text.match(/\t/g) || []).length;
    }
    rowLayoutByType(doc, row, type) {
        switch (type) {
            case 'textarea':
                // code block
                break;
            default:
            // code block
        }
    }


}
