import { useCallback, useContext, useEffect, useState, useRef } from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {stringify} from 'qs';
import {FetchContext} from 'App/Strapi/FetchContext';
import Block from 'UI/App/Components/Block/Block';
import Grid from 'UI/App/Components/Grid/Grid';
import PageHeader from "../../../../Components/PageHeader/PageHeader";
import capitalize from "lodash/capitalize";
import {_PURCHASE_QUOTE_STATUS_OPTIONS} from "UI/App/Partials/Content/Purchases/Quotes/PurchaseQuotesOverview";
import {formatName} from "App/Util/format";
import Button from "UI/App/Components/Button/Button";
import {closePopup, openPopup} from "UI/App/Components/Popup/Popup";
import Icon from "UI/App/Components/Icon/Icon";
import ConfirmationPopup from "UI/App/Components/Popup/Popups/Util/ConfirmationPopup";
import { useMutipleFileUpload } from 'UI/App/Components/Form/FileUpload/FileUpload';
import { downloadFile } from "App/Util/fetch";
import { SpinnerOverlay } from 'UI/App/Components/Spinner';

const statusOptions = [];
for (const status of _PURCHASE_QUOTE_STATUS_OPTIONS) {
    statusOptions.push(<option key={status} value={status}>{capitalize(status)}</option>);
}


export default function PurchaseQuote() {
    const {authAxios} = useContext(FetchContext);
    const params = useParams();

    const navigate = useNavigate();
    const [quoteData, setQuoteData] = useState(null);
    const [filesToLoad, setFilesToLoad] = useState([])
    const [isLoadingFiles, setIsLoadingFiles] = useState(true);
    const fileInputRef = useRef(null);

    const { MultipleFileUpload, attachments, setAttachments } = useMutipleFileUpload(
        'image/*',
        'Getekende pakbon uploaden',
        'camera',
        'Getekende pakbon'
    );

    useEffect(() => {
        authAxios
            .get(`/calculations/purchases/quotes/${params.purchaseQuoteId}?populate=*`)
            .then(({data}) => {
                setQuoteData(data.data);


                if (data.data?.attachments) {
                    // tell users were loading files
                    setIsLoadingFiles(true)
                    setFilesToLoad(data.data.attachments);
                }
            })
            .catch((exception) => {
                console.error(exception);
            });
    }, []);

    useEffect(() => {
        let files = []

        // loop through all files and start the request to download the files
        for (let attachment of filesToLoad) {
            files.push(downloadFile(authAxios, attachment?.id, attachment?.mime).then(async (blobURL) => {
                const blob = await fetch(blobURL).then(res => res.blob())

                return {
                    name: attachment?.name,
                    url: blobURL,
                    file: new File([blob], attachment?.name, { type: blob.type }),
                    id: attachment?.id
                }
            }))
        }

        // resolve the promises
        Promise.allSettled(files).then((result) => {
            let fulfilledFiles = result.filter((el) => el.status === "fulfilled").map((el) => el.value)


            if (fulfilledFiles.length !== files.length) {
                // singular or multiple err msg
                if (files.length - fulfilledFiles.length === 1) {
                    toast.error("Er is een fout opgetreden bij het ophalen van een pakbon")
                } else {
                    toast.error("Er is een fout opgetreden bij het ophalen van pakbonnen")
                }

            }

            // set the the atta chments
            setAttachments(fulfilledFiles)

            // were done loading
            setIsLoadingFiles(false)
        })



    }, [filesToLoad]);

    const updateQuote = useCallback(
        (value) => {
            authAxios
                .put(`/calculations/purchases/quotes/${quoteData?.id}`, {
                    data: {
                        status: value.toLowerCase()
                    }
                })
                .then(() => {
                    setQuoteData((prev) => {
                        return {...prev, status: value.toLowerCase()};
                    });
                    toast.success(
                        <>
                            Status omgezet naar <i>{value}</i>
                        </>
                    );
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(`Er ging iets mis met het bewerken van de inkoopofferte.`);
                });
        },
        [authAxios, quoteData?.id]
    );

    async function deletePurchaseQuote() {
        // Get orderId before deleting
        const orderId = quoteData?.order?.id

        await authAxios.delete(`/calculations/purchases/quotes/${quoteData.id}`).then(({data}) => {

        }).then(() => {
            toast.info('Inkoop bon verwijderd.')
        }).catch((error) => {
            console.error(error);
            toast.error(`Er ging iets mis met het verwijderen van de inkoopofferte.`);
        });

        navigate(`/orders/${orderId}`)
    }

    async function saveAttachments() {
        const formData = new FormData();

        // array to keep track of files to save
        let files = []
        if (attachments?.length > 0) {
            for (let attachment of attachments) {
                // if we DO have an ID its an files we already have
                if (attachment?.id) {
                    files.push({ id: attachment.id })

                } else if (attachment?.id === undefined) { // no ID means we have to add the file
                    formData.append('files.attachments', attachment.file, attachment.file.name);
                }

            }

        }

        // add the files that we want to keep
        formData.append('data', JSON.stringify({ attachments: files }))

        await authAxios.put(`/calculations/purchases/quotes/${params.purchaseQuoteId}`, formData)
            .then(() => {
                toast.success(`Order pakbon bijgewerkt!`);
            })
            .catch((error) => {
                console.error(error);
                toast.error(`Er ging iets mis met het opslaan van de pakbon`);
            });
    }

    return (
        <>
            <ConfirmationPopup
                popupID={"deletePurchaseQuoteConfirm"}
                title="Verwijderen"
                subject="Inkoopbon verwijderen"
                description={`Weet u zeker dat u inkoopbon ${quoteData?.number} wil verwijderen?`}
                functionOnConfirm={(e) => deletePurchaseQuote(e)}
            />
            <Grid columns='1' gap='1lh'>
                <PageHeader
                    color='white'
                    title={`Inkoopofferte: ${quoteData?.number}${
                        quoteData?.description !== null ? ` - ${quoteData?.description}` : ''
                    }`}
                    children={
                        <>
                            <Button className='btn btn--black btn--round' onClick={() => {
                                navigate(`/purchase/quotes/${quoteData.id}/edit`)
                            }}>
                                <Icon name='pencil'/>
                            </Button>
                            <Button className='btn btn--black btn--round' onClick={() => {
                                openPopup("deletePurchaseQuoteConfirm")
                            }}>
                                <Icon name='trash-can'/>
                            </Button>
                        </>
                    }
                />
                <Grid columns='2' customColTemplate='2fr 5fr'>
                    <Grid columns='1' gap='1lh'>
                        <Block name='quoteInfo' title='Informatie'>
                            <div>
                                <label htmlFor='status'>Status</label>
                                <select
                                    name='status'
                                    value={quoteData?.status}
                                    onChange={({ target }) => {
                                        updateQuote(target.value);
                                    }}
                                >
                                    {statusOptions}
                                </select>
                            </div>
                            <br />
                            <Grid columns='2'>
                                <b>Order</b>
                                <Link to={`/orders/${quoteData?.order?.id}`}>{quoteData?.order?.number}</Link>
                                <b>Leverancier</b>
                                <Link to={`/crm/suppliers/${quoteData?.supplier?.id}`}>{quoteData?.supplier?.name}</Link>
                                <b>Contact persoon</b>
                                <Link to={`/crm/suppliers/${quoteData?.supplier?.id}/contactpersons/${quoteData?.contactPerson?.id}`}>{formatName(quoteData?.contactPerson?.firstName, quoteData?.contactPerson?.prefixToSurname, quoteData?.contactPerson?.surname)}</Link>
                            </Grid>

                        </Block>
                        <SpinnerOverlay visible={isLoadingFiles} message={`Bestanden aan het ophalen...`}>
                            <Block
                                title='Getekende pakbon'
                                name='bijlage'
                                headerRightSideChildren={
                                    <div className={'page-header__content'}>
                                        {attachments.length > 0 && (
                                            <Button className={'btn btn--round'} onClick={(e) => {
                                                fileInputRef.current.click();
                                            }}>
                                                <Icon name="plus" />
                                                <input ref={fileInputRef} id='attachment' type='file' multiple accept={"*"} style={{ display: "none" }} onChange={event => {
                                                    let files = [];

                                                    // for all selected files 'format' to attachment type
                                                    for (let file of event.target.files ?? []) {
                                                        files.push({ file: file ?? null, name: file.name, url: '' })
                                                    }

                                                    // add new and old attachments
                                                    setAttachments((prev) => ([
                                                        ...prev,
                                                        ...files
                                                    ]));
                                                }} />
                                            </Button>

                                        )}
                                        < Button className={'btn btn--round'} onClick={() => saveAttachments()}>
                                            <Icon name="save" />
                                        </Button>
                                    </div>
                                }

                            >
                                <MultipleFileUpload />

                            </Block>
                        </SpinnerOverlay>

                    </Grid>
                    <Block name='quotePDF' title='PDF'>
                        <Grid columns='1'>
                            <embed
                                style={{
                                    height: '800px',
                                    width: '100%'
                                }}
                                type='application/pdf'
                                src={quoteData?.base64 ? `data:application/pdf;base64,${quoteData?.base64}` : ''}
                            />
                        </Grid>
                    </Block>
                </Grid>
            </Grid>
        </>
    );
}
