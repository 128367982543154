import { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { FetchContext } from 'App/Strapi/FetchContext';
import { stringify } from 'qs';
import Container from "UI/App/Components/Container/Container";
import { toast } from 'react-toastify';
import Table from 'UI/App/Components/Table/Table';
import { usePagination } from 'UI/App/Components/Pagination/Pagination';
import Icon from "UI/App/Components/Icon/Icon";
import { formatDateWithoutTime } from 'App/Util/format';
import { exportObject } from 'App/Util/exportObject';

export default function ViewMaterial() {
    const params = useParams();
    const { authAxios } = useContext(FetchContext);
    // create a state for the table data
    const [tableData, setTableData] = useState([]);

    // states to store filter options in
    const [users, setUsers] = useState([])
    const [categories, setCategories] = useState([])
    const [materials, setMaterials] = useState([])
    const [projects, setProjects] = useState([])

    // create a state for the table columns (sort order and column field)
    const [tableColumns, setTableColumns] = useState({
        sort: {
            column: 'id',
            direction: 'desc'
        },
        fields: [
            {
                name: 'Actie',
                field: 'action',
                sortable: true,
            },
            {
                name: 'Datum',
                field: 'dateTime',
                sortable: true,
            },
            {
                name: 'Gebruiker',
                field: 'user.id',
                sortable: true,
            },
            {
                name: 'Categorie',
                field: 'material.category',
                sortable: true,
            },
            {
                name: 'Materiaal',
                field: 'material.calculationDescription',
                sortable: true,
            },
            {
                name: 'Hoeveelheid',
                field: 'quantity',
                sortable: true,
            },
            {
                name: 'Order',
                field: 'order.id',
                sortable: true,
            }
        ]
    });

    // Enable pagination
    const {
        paging,
        filtering,
        currentPage,
        resultsPerPage,
        setTotalPages,
        setTotalResults,
        filterValues,
        filterQuery,
        paginationStateLoaded,
        setFilterValues
    } = usePagination({
        storageKey: 'order-materials',
        searchSettings: {
            enabled: true,
            strapiFields: [
                'action',
                '[user][id]',
                '[material][category]',
                '[material][calculationDescription]',
                '[order][id]'
            ]
        },
        filters: [
            {
                name: "action",
                type: "select",
                options: [
                    { name: 'Alle acties', value: '*' },
                    { name: 'Inboeken', value: 'inboeken' },
                    { name: 'Afboeken', value: 'afboeken' }
                ],
                defaultValue: '*',
                strapiFilterFields: ['action'],
            },
            {
                name: "user",
                type: "select",
                options: [
                    {
                        name: "Alle gebruikers", value: '*',
                    },
                    ...users
                ],
                defaultValue: '*',
                strapiFilterFields: ['[user][id]'],
            },
            {
                name: "category",
                type: "select",
                options: [
                    {
                        name: "Alle categorieën", value: '*',
                    },
                    ...categories
                ],
                defaultValue: '*',
                strapiFilterFields: ['[material][category]'],
            },
            {
                name: "material",
                type: "select",
                options: [
                    {
                        name: "Alle materialen", value: '*',
                    },
                    ...materials
                ],
                defaultValue: '*',
                strapiFilterFields: ['[material][calculationDescription]'],
            },
            {
                name: "project",
                type: "select",
                options: [
                    {
                        name: "Alle projecten", value: '*'
                    },
                    ...projects
                ],
                defaultValue: '*',
                strapiFilterFields: ['[order][id]'],
            }

        ],
        htmlElements: [
            <button onClick={() => { exportTimers() }} className='btn--black btn btn--icon-right'>
                Exporteren <Icon name='arrow-down' />
            </button>,
        ],
        buttonCollapseBreakpoint: 1960,
        resultsPerPageBreakpoint: 1960,
        paginationBreakpoint: 1960,
        resultCountBreakpoint: 1850,
        filterPopupBreakpoint: 99999, // As requested all filters should be in a popup, setting the width to something high will enforce this.
        showFilterClearButton: true,
    });

    // get all the filter options
    useEffect(() => {
        // by specifically populating the needed fields speed up response times
        const orderQuery = stringify({
            populate: {
                project: {
                    fields: ["id"]
                },
            },
            fields: ["id", "number", "description"],
            sort: {
                number: 'desc'
            },
        })

        // get the orders/projects
        authAxios.get(`/orders/all?${orderQuery}`).then(({ data }) => {
            setProjects(data.map((entry) => { return { value: entry?.id, name: `${entry?.number} - ${entry?.description}` } }))
        }).catch((exception) => {
            toast.error(
                `Er is iets fout gegaan met het ophalen van de projecten ${exception?.response?.status ? `(${exception.response?.status})` : ''
                } `
            );
            console.error(exception);
        });

        const userQuery = stringify({
            fields: ["id", "username"],
            sort: {
                username: 'asc'
            }
        })

        // get the users
        authAxios.get(`../users?${userQuery}`).then(({ data }) => {
            setUsers(data.map((entry) => { return { value: entry?.id, name: entry?.username } }))
        }).catch((exception) => {
            toast.error(
                `Er is iets fout gegaan met het ophalen van de gebruikers ${exception?.response?.status ? `(${exception.response?.status})` : ''
                } `
            );
            console.error(exception);
        });
        
        // get all the material categories
        authAxios.get(`/logistics/materials/categories`).then(({ data }) => {
            setCategories(data.categories.map((entry) => { return { value: entry, name: entry } }))
        }).catch((exception) => {
            toast.error(
                `Er is iets fout gegaan met het ophalen van de categorieën ${exception?.response?.status ? `(${exception.response?.status})` : ''
                } `
            );
            console.error(exception);
        })
    }, [])

    function getMaterials(materialsQuery) {
        authAxios.get(`/logistics/materials/all?${materialsQuery}`).then(({ data }) => {    
            // group by category
            const categoryGroups = data.reduce((groupedByCategory, material) => {
                const { category, calculationDescription } = material;
                if (!category || !calculationDescription) {
                    return groupedByCategory;
                }
                if (!groupedByCategory[category]) {
                    groupedByCategory[category] = [];
                }
                groupedByCategory[category].push({ value: calculationDescription, name: calculationDescription });
                return groupedByCategory;
            }, {});
    
            const options = Object.keys(categoryGroups).map(category => ({
                name: category,
                options: categoryGroups[category] || []
            }));
    
            setMaterials(options);
        }).catch((exception) => {
            toast.error(
                `Er is iets fout gegaan met het ophalen van de materialen ${exception?.response?.status ? `(${exception.response?.status})` : ''} `
            );
            console.error(exception);
        });
    }

    // get materials based on the selected category
    useEffect(() => {
        if (filterValues.category && filterValues.category !== '*') {
            const materialsQuery = stringify({
                fields: ["category", "calculationDescription"],
                filters: {
                    category: {
                        $eq: filterValues.category
                    }
                }
            })

            getMaterials(materialsQuery);
        } else {
            const materialsQuery = stringify({
                fields: ["category", "calculationDescription"]
            })

            getMaterials(materialsQuery);
        }
    }, [filterValues.category])

    // on params set/change
    useEffect(() => {
        // check for orderId in the params
        if (params?.orderId !== undefined) {
            //  set the filter to only return results from that order
            setFilterValues(prev => ({
                ...prev,
                project: params?.orderId,
            }))
            return;
        }
    }, [params])


    function getIconName(action) {
        switch (action) {
            case 'inboeken':
                return 'plus';
            case 'reserveren':
                return 'clipboard-check';
            case 'afboeken':
                return 'arrow-down';
            case 'verwijderen':
                return 'trash-can';
            default:
                return action;
        }
    }
    
    function getIconColor(action) {
        switch (action) {
            case 'inboeken':
                return 'green';
            case 'reserveren':
                return 'grey';
            case 'afboeken':
                return 'red';
            case 'verwijderen':
                return 'red';
            default:
                return action;
        }
    }

    // get the table data using the useEffect hook
    useEffect(() => {
        // wait for pagination
        if (!paginationStateLoaded) return;

        // build the pagination query
        let query = buildSearchParams(resultsPerPage, currentPage, tableColumns);
        
        authAxios
            .get(`/logistics/materials/stock/history?${query}&${filterQuery}`)
            .then(({ data }) => {

                // create temp array to store formatted data in
                let tableDataArray = [];
        
                // format data to be usable for table component
                for (const entry of data.data) {

                    // get icon name and color
                    const iconName = getIconName(entry.action);
                    const iconColor = getIconColor(entry.action);

                    // push formatted data into tableDataArray
                    tableDataArray.push(
                        {
                            attributes: {},
                            data: [
                                <Icon name={iconName} style={{ fill: iconColor, maxHeight: '15px'}} />, // action with icon
                                formatDateWithoutTime(entry.dateTime), // dateTime
                                entry.user.username ?? '-', // user
                                entry.material.category ?? '-', // material category
                                entry.material.calculationDescription ?? '-', // material
                                entry.quantity + ' ' + entry.unit ?? '-', // quantity
                                entry.order?.number ?? '-' // project id
                            ],

                        }
                    )
                }
        
                // save table data in useState
                setTableData(tableDataArray);
        
                // Set pagination data
                const paginationData = data?.meta?.pagination;
                setTotalPages(paginationData?.pageCount);
                setTotalResults(paginationData?.total);
            }).catch((exception) => {
                toast.error(
                    `Er is iets fout gegaan met het ophalen van de materialen ${exception?.response?.status ? `(${exception.response?.status})` : ''
                    } `
                );

                console.error(exception);
            });
    }, [currentPage, resultsPerPage, authAxios, setTotalPages, setTotalResults, filterQuery, tableColumns, paginationStateLoaded]);


    function buildSearchParams(resultsPerPage, currentPage, tableColumns) {
        let queryParams = {
            pagination: {
                page: currentPage,
                pageSize: resultsPerPage
            },
            populate: {
                material: {
                    fields: ["id", "category", "calculationDescription"]
                },
                user: {
                    fields: ["id", "username"]
                },
                order: {
                    fields: ["id", "number"],
                }
            },
            fields: ["action", "dateTime", "quantity", "unit"],
            sort: `${tableColumns.sort.column}:${tableColumns.sort.direction}`,
            filters: {
                action: {
                    $notIn: ['reserveren', 'verwijderen']
                }
            }
        };
    
        return stringify(queryParams, {
            encodeValuesOnly: true // prettify URL
        });
    }

    function exportTimers() {
        let query = stringify({
            populate: {
                material: {
                    fields: ["id", "category", "calculationDescription"]
                },
                user: {
                    fields: ["id", "username"]
                },
                order: {
                    fields: ["id", "number"],
                }
            },
            fields: ["action", "dateTime", "quantity", "unit"],
            filters: {
                action: {
                    $notIn: ['reserveren', 'verwijderen']
                }
            }
        });
        authAxios
            .get(`/logistics/materials/stock/history/all?${query}&${filterQuery}`)
            .then(({ data }) => {
                let formatedOutput = data.map((el) => {


                    return {
                        "Actie": el?.action ?? "",
                        "Datum": el?.dateTime ?? "N.V.T.",
                        "Gebruiker": el?.user?.username ?? "N.V.T.",
                        "Categorie": el?.material?.category ?? "N.V.T.",
                        "Materiaal": el?.material?.calculationDescription ?? "N.V.T.",
                        "Hoeveelheid": `${el.quantity} ${el.unit ?? "??"}`,
                        "Order": el?.order?.number ?? "N.V.T."
                    }
                })
                exportObject(formatedOutput, {
                    filename: `Materiaalverantwoording_export-${new Date().toLocaleDateString("nl-NL", { year: "numeric", month: "2-digit", day: "2-digit" })}`, // date in DD-MM-YYYY
                    fieldSeparator: ';',
                    decimalSeparator: 'locale',
                    useKeysAsHeaders: true,
                    showColumnHeaders: true,
                    useBom: true,
                })
            })
    }
    return (
        <div>
            {filtering}
            <Container>
                <Table
                    headers={[
                        'Actie',
                        'Datum',
                        'Gebruiker',
                        'Categorie',
                        'Materiaal',
                        'Hoeveelheid',
                        'Order',
                    ]}
                    structure={{
                        default: ['100px', '150px', '25%', '25%', '25%', '175px', '25%']
                    }}
                    data={tableData}
                    tableColumns={tableColumns}
                    setTableColumns={setTableColumns}
                    hover
                    border='row'
                    overflowText={true}
                />
            </Container>
            {paging}
        </div>
    )
}