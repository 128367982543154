import { Fragment, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import "./input.scss";
import Icon from "../Icon/Icon";
import Select from "react-select";

/*
data oject properties:
{
	type: string - input type
	id: string - input id tag. if ommitted, name is used for the id
	name: string - required! | for name attribute
	label: string - text for the label. empty = no label
	placeholder: string - input placeholder
	title: string - text for the element title on mouse hover
	class: string - input class
	required: bool
	disabled: bool
	readonly: bool
	value: mixed - sets the default value of the input
	onChange: function - add custom functionality to the input
	options: array - options for select and checkboxes
		[
			{
				id: string,
				value: string,
				text: string,
				checked: true,
			}, {
				id: string,
				value: string,
				text: string,
			}
		]
	subFields: array - group multiple inputs into 1 input group div
	[
		{
			same attributes
		},
	]
	pattern: regexp - Specifies a regular expression that an <input> element's value is checked against
	max: number - Specifies the maximum value for an <input> element
	min: number - Specifies the minimum value for an <input> element
	maxLength: number - Specifies the maximum number of characters allowed in an <input> element
	minLength: number - Specifies the minimum number of characters allowed in an <input> element
	step: number - Specifies the interval between legal numbers in an input field
	cols: number - Specifies the visible width of a text area
	rows: number - Specifies the visible number of lines in a text area
	accept: string - the file extensions/type allowed by input type file
	direction: 'row'/'col' - The direction of multiple checkboxes
	component: string - the component name for dynamic content (e.g: "data.tag-group")
	file: string - url to the image stored in strapi (file input)
	alt: string - alt text for the image preview (file input)
}
*/

export default function Inputs({ inputs }) {
	/**
	 * Is supposed to handle nested multiple types
	 * not 100% working with nested multiples but works fine for single multiple type
	 */
	function handleTypeMultiple(subData) {
		// set name as id if no id was given
		if (!subData.id) {
			subData.id = subData.name;
		}

		// check if the sub field also is of type multiple
		if (subData.type === 'multiple') {
			// add another group to the current group
			return (
				<div key={`input-multiple-${subData.id}`} className="input-multiple" style={subData?.style ?? {}}>
					{subData.label ? (<label key={`input-group-${subData.id}-subGroupLabel`} htmlFor={subData.subFields[0].name} className={subData.required ? 'required' : ''}>{subData.label}</label>) : ''}
					<div key={`input-group-${subData.id}`} className="input-group">
						{subData.subFields.map((subData) => {
							return handleTypeMultiple(subData);
						})}
					</div>
				</div>
			);
		} else {
			// return the inputs in this group
			return (
				<Fragment key={`input-group-${subData.id}-fragment`}>
					{subData.label ? (<label key={`input-group-${subData.id}-singleLabel`} htmlFor={subData.name} className={subData.required ? 'required' : ''}>{subData.label}</label>) : ''}
					{CreateInput(subData)}
					{subData.footNote ? (<small htmlFor={subData.name} >{subData.footNote}</small>) : ''}
				</Fragment>
			)
		}
	}

	/**
	 * Creates the input based on type
	 */
	function CreateInput(data) {
		// create state for the input element which is needed for the updating value
		const [inputValue, setInputValue] = useState(data?.value ?? data?.defaultValue ?? undefined);
		const [isChecked, setIsChecked] = useState(data?.checked ?? undefined);

		// states & ref to handle file inputs
		const fileRef = useRef();
		const [fileDelete, setFileDelete] = useState(false);
		const [fileSize, setFileSize] = useState({ style: { width: undefined, height: undefined } });

		// Warning: Internal React error: Expected static flag was missing. Please notify the React team.
		useEffect(() => {
			setIsChecked(data?.checked ?? undefined);
		}, [data?.checked, data?.value]);

		// set attributes for the input element
		const attributes = {
			type: data.type,
			id: data.id,
			name: data.name,
			placeholder: data.placeholder,
			title: data.title,
			alt: data.alt,
			className: data.class,
			required: data.required,
			disabled: data.disabled,
			readOnly: data.readonly,
			pattern: data.pattern,
			max: data.max,
			min: data.min,
			maxLength: data.maxLength,
			minLength: data.minLength,
			step: data.step,
			cols: data.cols,
			rows: data.rows,
			accept: data.accept,
			style: data.style,
			options: data.options,
			value: inputValue ?? data.value ?? data.defaultValue,
			'data-dynamic-component': data.component,
			...(data.type === 'multiSelect' ? {
				isMulti: data?.isMulti ?? undefined,
				closeMenuOnSelect: data.closeMenuOnSelect,
			} : {}),
			// checked: isChecked,
			onFocus: ({ target }) => {
				// do custom functionality
				if (typeof (data.onFocus) === 'function') {
					data.onFocus(target);
				}
			},
			onBlur: ({ target }) => {
				// do custom functionality
				if (typeof (data.onBlur) === 'function') {
					data.onBlur(target);
				}
			},
			onChange: (event) => {
				const target = event.target;
				// do custom functionality
				if (typeof (data.onChange) === 'function') {
					data.onChange((data.fullEvent === true ? event : target));
					return;
				}
				if (Object.hasOwn(target, 'checked') || target.hasOwnProperty('checked')) {
					// update this input checked state
					setIsChecked(target.checked);
				}
				else if (target.files) {
					if (target.files[0]) {
						// change image + preview
						const reader = new FileReader();

						reader.onload = (e) => {
							// load dataURL as image to get width & height
							const image = new Image();
							image.src = e.target.result;
							image.onload = () => {
								// access image size here
								const width = image?.naturalWidth < image?.naturalHeight ? `auto` : `${image?.naturalWidth}px`;
								const height = image?.naturalHeight <= image?.naturalWidth ? `auto` : `${image?.naturalHeight}px`;
								setFileSize({ style: { width, height } });
							};

							setInputValue(e.target.result);
						}

						reader.readAsDataURL(target.files[0]);
					}
					else {
						// revert preview to initial
						setInputValue(data?.value);
					}
				}
				else {
					// update this input value state
					setInputValue(target.value);
				}


			}
		}

		// check for input types
		if (data.type === 'switch') {
			return (
				<div key={`switch-${data.id}`} className={`toggle-switch-wrapper`}>
					<input {...attributes} type='checkbox' key={`switch-${attributes?.id}`} className={`toggle-switch ${attributes?.className ?? ''}`}
						checked={isChecked}
						onChange={attributes.onChange} />
				</div>
			);
		}
		else if (data.type === 'hidden') {
			return (
				<Fragment key={`input-group-${data.id}`}>
					<input type={'hidden'} {...attributes} value={data.value} />
				</Fragment>
			)
		}
		else if (data.type === 'checkbox' || data.type === 'radio') {
			let options = [];
			let name = attributes?.name;

			// Loop through options
			for (const optionData of data.options) {
				if (optionData === undefined) {
					continue;
				}

				// sets string to lowercase
				function getValueAsLowerCase(value) {
					if (Array.isArray(value)) {
						return value.map((entry) => {
							if (typeof entry === 'string') {
								return entry.toLowerCase();
							} else {
								return entry;
							}
						});
					} else if (typeof value === 'string') {
						return value.toLowerCase();
					} else {
						return value;
					}
				}

				const checked = getValueAsLowerCase(data.value);

				// add option to options array
				options.push(
					<div key={`${data.type}-${data.id}-${optionData.value}`} className={`${data.type}__wrapper ${data.direction || ''}`}>
						<input {...attributes} type={attributes.type} id={`${name}${optionData.value}`} name={attributes?.name} defaultValue={optionData.value} defaultChecked={optionData.checked || (checked === optionData.value || checked?.includes(optionData.value))} onChange={attributes.onChange} />
						<label htmlFor={`${name}${optionData.value}`} className={`crm-label-${data.type}`}>
							{optionData.text}
						</label>
					</div>
				)
			}

			return (<div className='checkbox__container'>{options}</div>);
		}
		else if (data.type === 'select') {
			let options = [];

			// Loop through options
			for (const [index, optionData] of Object.entries(data.options)) {
				if (optionData === undefined) {
					continue;
				}

				options.push(<option key={`select-option-${index}`} value={optionData.value}>{optionData.text}</option>)
			};

			// remove defaultValue attribute.
			// delete attributes.defaultValue;

			return (
				<select key={`select-${data.id}`} {...attributes}>
					{options}
				</select>
			);
		} else if (data.type === 'multiSelect') {
			// set values
			attributes.value = data.value;
			return (
				<Select key={`multiSelect-${data.id}`} {...attributes} />
			);
		}
		else if (data.type === 'textarea') {
			// remove type attribute.
			delete attributes.type;

			return <textarea key={`textarea-${data.id}`} /*defaultValue={defaultValue}*/ {...attributes} defaultValue={data?.defaultValue} />;
		}
		else if (data.type === 'file') {
			// to do: change alt

			// remove value attribute, files CANNOT be set programmatically!
			// react does not allow for this as it would pose a security risk
			delete attributes.defaultValue;
			delete attributes.value

			return (
				<div className='file-upload'>
					{inputValue && <div className="file-upload__preview__container">
						<div className="file-upload__preview__wrapper"
						// {...fileSize}
						>
                            <div className='overflow-hidden--center'>
                                <img
                                    src={inputValue}
                                    alt={attributes?.alt}
                                    className='file-upload__preview-img'
                                    // {...fileSize}
                                />
                            </div>
						</div>
							<button
								type="button"
								className={'file-upload__delete-btn btn btn--transparent btn--icon-red'}
								onClick={() => { setInputValue(undefined); setFileDelete(true); fileRef.current.value = ""; }}
								title="verwijder afbeelding">
								<Icon name='close' />
							</button>
					</div>
					}

					<input ref={fileRef} key={`${data.type}-${data.id}`} {...attributes} data-delete-image={fileDelete} onChange={(e) => {
						setFileDelete(false);
						attributes.onChange(e);
					}} />
				</div>
			);
		} else if (data.type === 'submit') {
			return <button className={'btn'} key={`${data.type}-${data.id}`} {...attributes}>{data?.title ?? 'Opslaan'} <Icon name={'save'} /></button>;
		}
		// other inputs
		else if (data.type !== undefined) {
			return <input key={`${data.type}-${data.id}`} {...attributes} defaultValue={data?.defaultValue !== null ? data?.defaultValue : ''} />;
		}
		else {
			return;
		}
	}


	// return the created html
	return (<>
		{inputs !== null ? inputs.map((data) => {
			// set name as id if no id was given
			if (!data.id) {
				data.id = data.name;
			}

			// check if the type is multiple or not
			if (data.type === 'multiple') {
				// create a group for the multiple inputs
				return (
					<div key={`input-multiple-${data.id}`} className="input-multiple">
						{data.label ? (<label key={`input-group-${data.id}-groupLabel`} htmlFor={data.subFields[0].name} className={data.required ? 'required' : ''}>{data.label}</label>) : ''}

						<div key={`input-group-${data.id}`} className="input-group">
							{data.subFields.map((subData) => {
								return handleTypeMultiple(subData);
							})}
						</div>
					</div>
				);
			}
			else if (data.type === 'hidden') {
				return (
					<Fragment key={`input-group-${data.id}`}>
						{data.label ? (<label htmlFor={data.name} className={data.required ? 'required' : ''}>{data.label}</label>) : ''}
						{CreateInput(data)}
						{data.footNote ? (<small htmlFor={data.name} >{data.footNote}</small>) : ''}
					</Fragment>
				)
			}
			else {
				// return the single input in a group
				return (
					<div key={`input-group-${data.id}`} className="input-group">
						{data.label ? (<label htmlFor={data.name} className={data.required ? 'required' : ''}>{data.label}</label>) : ''}
						{CreateInput(data)}
						{data.footNote ? (<small htmlFor={data.name} >{data.footNote}</small>) : ''}
					</div>
				)
			}
		}) : <></>}
	</>);
}

export async function getFormData({ target }, authAxios) {
	const data = {};
	for (const input of target) {
		// skip empty values & submit button
		if (input.type === 'submit') {
			continue;
		}

		// handle file uploads
		if (input.type === 'file') {
			if (input.dataset.deleteImage === 'true') {
				// set data to null to delete from strapi
				data[input.name] = null;
			} else {
				if (input.files[0] === undefined) {
					continue
				}
				// upload file
				// file upload must be in FormData?
				const formData = new FormData();
				// name must be "files"
				formData.append("files", input.files[0]);

				await authAxios.post('../upload',
					formData
				).then((file) => {
					// add file id to data object
					data[input.name] = file.data[0].id;
				}).catch((e) => {
					toast.error('Er ging iets mis met het uploaden van de afbeelding.');

					console.error(e);
				});
			}
		}
		// check if input is part of an array/object.
		else if (/\[.*?\]/.test(input.name)) {

			// get the strapiField parent and keys from the input name, then filter out empty values from the array.
			const [strapiField, ...keys] = input.name.split(/\[(.*?)\]/).filter(m => m);

			// check for non-checkbox inputs or only checked checkboxes. else if checkbox add to object for clearing field in strapi
			if (input.type !== 'checkbox' || (input.type === 'checkbox' && input.checked)) {
				// if (input.type === 'checkbox') {
				// if the key field doesnt already exist in the formData.
				if (!data?.[strapiField]?.[keys[0]]) {

					// create the key in the formData
					data[strapiField] = { ...data[strapiField], [keys[0]]: input.value }
				}
				// if the key field does exist in the formData.
				else {
					// create array & append the value
					if (Array.isArray(data?.[strapiField]?.[keys[0]])) {
						data[strapiField] = { ...data[strapiField], [keys[0]]: [...data?.[strapiField]?.[keys[0]], input.value] }
					} else {
						data[strapiField] = { ...data[strapiField], [keys[0]]: [data?.[strapiField]?.[keys[0]], input.value] }
					}
				}
			} else {
				if (input.type === 'checkbox') {
					if (!data?.[strapiField]?.[keys[0]]) {

						// create the key in the formData
						data[strapiField] = { ...data[strapiField], [keys[0]]: [] }
					}
				}
			}
		}
		else {

			if (input.type === 'checkbox') {
				data[input.name] = input.checked
			} else {
				// add value to data
				data[input.name] = input.value;
			}
		}
	}

	return data;
}
