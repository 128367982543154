import { download, generateCsv, mkConfig } from "export-to-csv";

export function exportObject(csvData, csvFileOptions = {
    filename: `Unknow_export-${new Date().toLocaleDateString("nl-NL", { year: "numeric", month: "2-digit", day: "2-digit" })}`, // date in DD-MM-YYYY
    fieldSeparator: ';',
    decimalSeparator: 'locale',
    useKeysAsHeaders: true,
    showColumnHeaders: true,
    useBom: true,
}) {
    // available options at: https://github.com/alexcaza/export-to-csv#api
    const csv = generateCsv(csvFileOptions)(csvData);

    // download csv
    download(csvFileOptions)(csv);
}