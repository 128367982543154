import { useContext, useEffect, useState } from 'react';
import { FetchContext } from "App/Strapi/FetchContext";
import { toast } from "react-toastify";
import { mkConfig, generateCsv, download } from "export-to-csv";
import Popup from "UI/App/Components/Popup/Popup";
import Grid from 'UI/App/Components/Grid/Grid';
import FancyInput from 'UI/App/Components/Form/fancyInput';
import Block from 'UI/App/Components/Block/Block';
import Button from 'UI/App/Components/Button/Button';
import Icon from 'UI/App/Components/Icon/Icon';
import Select from "react-select";
import objSet from "lodash/set";
import objGet from "lodash/get";
import qs from "qs";

export default function ExportCompaniesPopup({ fields = {}, setFields, crmFilter, exportContentType = "companies" }) {
    const { authAxios } = useContext(FetchContext);
    const [filterInputs, setFilterInputs] = useState(<></>)
    const [exportSettings, setExportSettings] = useState({
        exportType: { value: 0, label: "Alle bedrijven" }
    })

    //
    const colourStyles = {
        control: (styles) => ({ ...styles, backgroundColor: 'white' }),
        option: (styles, { data }) => {
            const color = data.color;

            return {
                ...styles,
                borderLeft: `8px ${color} solid`
            };
        }
    };


    useEffect(() => {
        let inputs = []

        for (let field of fields) {
            // if we dont have an identifier skip
            if (field?.name === undefined || field?.name === "") {
                continue;
            }

            // prep the fields inside the wrapper
            let innerInputs = []
            for (let input of field.values) {
                // if we dont have an identifier skip
                if (input?.name === undefined || input?.name === "") {
                    continue;
                }
                innerInputs.push(
                    <FancyInput id={input?.name} type={"checkbox"} size={20} label={input.label} value={input.value} onChange={(e) => toggleField(e, field?.name, input?.name)} />
                )
            }

            // main wrapper with dropdown
            inputs.push(
                <div key={field?.name} className={`${field?.name}-wrapper`}>

                    <Grid columns={2} alignItems={"center"} customColTemplate={"1fr 50px"} justify gap={"0px"} onClick={(e) => toggleDropDown(e, field?.name)} style={{ userSelect: 'none', cursor: 'pointer' }}>
                        <b>{field?.label ?? field.name}</b>
                        <Button className={'btn btn--transparent'}>{!field.expanded ? <Icon name='angle-up' /> : <Icon name='angle-down' />}</Button>
                    </Grid>
                    <Block useSmallerPadding={true} name={`${field?.name}-filterSelector`} style={{ paddingTop: "0px", display: field.expanded ? "" : "none" }} >
                        <Grid columns={1} gap={'2px'}>
                            {innerInputs}
                        </Grid>
                    </Block>
                </div>
            )
        }
        setFilterInputs(inputs)

    }, [fields])

    /**
     * Used later to add the populate 'prefix' <- explanations credits to @ardin
     *
     * @param arr
     * @param x
     * @return {*}
     */
    function interleave(arr, x) {
        return arr.flatMap(e => [e, x])
            .slice(0, -1)
    }

    function objectFilter(obj, predicate) {
        return Object.keys(obj)
            .filter(key => predicate(obj[key]))
            .reduce((res, key) => (res[key] = obj[key], res), {});
    }
    function toggleDropDown(e, name) {
        const currentState = fields;

        let foundField = currentState.find((el) => el.name === name)
        foundField.expanded = !foundField.expanded

        setFields([...currentState])
    }

    function toggleField(e, name, inputName) {
        const currentState = fields;

        let foundField = currentState.find((el) => el.name === name)

        let foundInput = foundField.values.find((el) => el.name === inputName)
        foundInput.value = !foundInput.value
        setFields([...currentState])
    }

    async function exportCompanies(settings, fieldsToExport) {
        // based on exportType do diffrent things
        if (settings.exportType.value === 0) {// export all entries
            const query = createQuery(fieldsToExport, settings?.exportCustomFields ?? false)

            // apply filter for this export type
            query.filters = {
                archived: settings.withArchived ? undefined : false
            }

            getCompanies(qs.stringify(query), undefined, settings?.exportCustomFields ?? false)

        } else if (settings.exportType.value === 1) {// export on CRM filter
            const query = createQuery(fieldsToExport, settings?.exportCustomFields ?? false)

            // add already formated filter to the end
            getCompanies(qs.stringify(query), crmFilter, settings?.exportCustomFields ?? false)

        } else if (settings.exportType.value === 2) {// export all HJMG companies
            const query = createQuery(fieldsToExport, settings?.exportCustomFields ?? false)

            // apply filter for this export type
            query.filters = {
                archived: settings.withArchived ? false : undefined,
                managingCompany: {
                    HJMG: {
                        $eq: true
                    }
                }
            }

            getCompanies(qs.stringify(query), undefined, settings?.exportCustomFields ?? false)

        } else if (settings.exportType.value === 3) {// export all Pretore companies
            const query = createQuery(fieldsToExport, settings?.exportCustomFields ?? false)

            // apply filter for this export type
            query.filters = {
                archived: settings.withArchived ? false : undefined,
                managingCompany: {
                    Pretore: {
                        $eq: true
                    }
                }
            }

            getCompanies(qs.stringify(query), undefined, settings?.exportCustomFields ?? false)

        }
    }

    function createQuery(fields, customFilters) {

        let wantedFields = fields.filter((el) => el.expanded === true)

        // if we have NO custom query return one that gets all the things we need
        if (wantedFields.length === 0 || !customFilters) {
            return {

                populate: {
                    address: true,
                    logo: true,
                    telephone: true,
                    contactPersons: {
                        populate: {
                            address: true,
                            telephone: true,
                            personalCellPhone: true,
                            mobilePhone: true,
                            notes: {
                                populate: {
                                    content: true,
                                    createdAt: true,
                                    user: {
                                        fields: [
                                            "username"
                                        ]
                                    }
                                },
                                sort: 'createdAt:desc'
                            }
                        }

                    },
                    notes: {
                        populate: {
                            content: true,
                            createdAt: true,
                            user: {
                                fields: [
                                    "username"
                                ]
                            }
                        },
                        sort: 'createdAt:desc'
                    },
                    managingCompany: {
                        fields: ["HJMG", "Pretore", "exactHJMG", "exactPretore"]
                    }
                },
                sort: 'name:asc',
            }
        }

        // empty object to store custom query in
        let customQuery = {}

        for (let field of wantedFields) {
            // filter out the values that we acutly want
            let wantedValues = field.values.filter((el) => el.value === true)

            let fields = customQuery.fields ?? []

            for (let value of wantedValues) {

                // if we dont have the relation name  add it
                if (field.relationFieldName !== undefined && !value.name.startsWith(field.relationFieldName)) {
                    value.name = `${field.relationFieldName}.${value.name}`
                }

                // if there are deeper populations handle those
                if (value.name.includes('.')) {
                    // if we want to populate instead of getting the fields
                    if (value?.populate === true) {
                        // split value name on .
                        let splitValue = value.name.split('.');

                        // remove the field from path
                        let valueToPopulate = splitValue.pop()

                        // add populate infront of very field
                        splitValue = interleave(splitValue, "populate")
                        splitValue.unshift("populate")
                        splitValue.push("populate")

                        // recombine in to a path
                        let objPath = splitValue.join(".")

                        // find the wanted object
                        let foundValues = objGet(customQuery, objPath)

                        // set the population to the passed bool
                        foundValues[valueToPopulate] = value?.populate

                    } else {
                        // split value name on .
                        let splitValue = value.name.split('.');

                        // remove the field from path
                        let valueToGet = splitValue.pop()

                        // add populate infront of very field
                        splitValue = interleave(splitValue, "populate")
                        splitValue.unshift("populate")


                        // add fields 'array' to path
                        splitValue.push("fields")
                        let objPath = splitValue.join(".")
                        let foundValues = objGet(customQuery, objPath) ?? [];

                        foundValues.push(valueToGet)
                        objSet(customQuery, objPath, foundValues)
                    }
                } else { // normal field, just add to field array
                    // if its stated to be a field to populate do that instead
                    if (value?.populate === true) {
                        let populate = customQuery?.populate ?? {}
                        populate[value.name] = value.populate
                    } else {
                        fields.push(value.name)
                    }

                }

            }
            customQuery.fields = fields

        }

        // if we have NO fields to populate get id
        if (customQuery.fields.length === 0) {
            customQuery.fields = ["id"]
        }
        return customQuery
    }

    async function getCompanies(query, crmFilter = undefined, customFilters = false) {
        let csvData = [];

        // tell user somethings happening
        let toastId = toast.loading("Bedrijvenlijst exporteren...");

        // if the we dont have a CRM filter we dont need to add an '&'
        crmFilter = crmFilter ? `&${crmFilter}` : ""

        // await api
        await authAxios.get(`/crm/${exportContentType}/all?${query}${crmFilter}`).then(async ({ data }) => {
            if (!data || (data?.length ?? 0) === 0) {
                toast.update(
                    toastId,
                    {
                        render: `Er zijn geen ${exportContentType === "companies" ? "bedrijven" : "leveranciers"} om te exporteren.`,
                        type: "info",
                        isLoading: false,
                        autoClose: 3000,
                        closeOnClick: true
                    })


                // stop the function
                return;
            }

            let keysWithValues = [];
            // loop through each company
            for (const company of data) {
                let companyDataRows = [];
                // if company has no contact people, use array with 1 empty object to still add a row for the company.
                const companyContactPeople = (company?.contactPersons?.length ?? 0) !== 0 ? company?.contactPersons : [{}];
                let companyNotes = ""
                company?.notes?.map((entry) => { companyNotes += `${entry.content}\r\n -${entry?.user?.username}\r\n ${new Date(entry.createdAt).toLocaleString()} \r\n\r\n` });
                // loop through each contactperson per company (or the empty object if no contact people)
                for (const contactPerson of companyContactPeople ?? [{}]) {

                    let contactPersonNotes = ""
                    contactPerson?.notes?.map((entry) => { contactPersonNotes += `${entry.content}\r\n -${entry?.user?.username}\r\n ${new Date(entry.createdAt).toLocaleString()}  \r\n\r\n` });
                    // push row per person
                    let newRow = {
                        [exportContentType === "companies" ? "Bedrijf ID" : "Leverancier ID"]: `${company?.id}` ?? '',
                        'Bedrijfsnaam': company?.name ?? '',
                        'Type bedrijf': company?.type ?? '',
                        'Branch': company?.branch ?? '',
                        'KVK nummer': company?.kvk ?? '',
                        'Adres regel 1': company?.address?.addressLine1 ?? '',
                        'Adres regel 2': company?.address?.addressLine2 ?? '',
                        'Adres regel 3': company?.address?.addressLine3 ?? '',
                        'Postcode': company?.address?.postalCode ?? '',
                        'Stad': company?.address?.city ?? '',
                        'Provincie': company?.address?.province ?? '',
                        'Land': company?.address?.country ?? '',
                        'Algemeen e-mailadres': company?.generalEmail ?? '',
                        'Facturatie e-mailadres': company?.invoiceEmail ?? '',
                        'Telefoonnummer': `${company?.telephone?.dialingCode ? '+' + company?.telephone?.dialingCode + ' ' : ''}${company?.telephone?.telephoneNumber ?? ''}`,
                        'Website': company?.website ?? '',
                        'Logo': company?.logo?.name ?? '',
                        'Gearchiveerd': company?.archived ? 'ja' : 'nee',
                        'Contactpersoon ID': contactPerson?.id ?? '',
                        'Contactpersoon benadering': contactPerson?.approach ?? '',
                        'Contactpersoon geslacht': contactPerson?.gender ?? '',
                        'Contactpersoon voorletter': contactPerson?.initials ?? '',
                        'Contactpersoon titel': contactPerson?.title ?? '',
                        'Contactpersoon voornaam': contactPerson?.firstName ?? '',
                        'Contactpersoon tussenvoegsel': contactPerson?.prefixToSurname ?? '',
                        'Contactpersoon achternaam': contactPerson?.surname ?? '',
                        'Contactpersoon achter titel': contactPerson?.titleAfter ?? '',
                        'Contactpersoon functie': contactPerson?.function ?? '',
                        'Contactpersoon adres regel 1': contactPerson?.address?.addressLine1 ?? '',
                        'Contactpersoon adres regel 2': contactPerson?.address?.addressLine2 ?? '',
                        'Contactpersoon adres regel 3': contactPerson?.address?.addressLine3 ?? '',
                        'Contactpersoon postcode': contactPerson?.address?.postalCode ?? '',
                        'Contactpersoon stad': contactPerson?.address?.city ?? '',
                        'Contactpersoon provincie': contactPerson?.address?.province ?? '',
                        'Contactpersoon land': contactPerson?.address?.country ?? '',
                        'Contactpersoon e-mailadres': contactPerson?.address?.email ?? '',
                        'Contactpersoon privé e-mailadres': contactPerson?.address?.personalEmail ?? '',
                        'Contactpersoon telefoonnummer': `${contactPerson?.telephone?.dialingCode ? '+' + contactPerson?.telephone?.dialingCode + ' ' : ''}${contactPerson?.telephone?.telephoneNumber ?? ''}`,
                        'Contactpersoon privé nummer': `${contactPerson?.personalCellPhone?.dialingCode ? '+' + contactPerson?.personalCellPhone?.dialingCode + ' ' : ''}${contactPerson?.personalCellPhone?.telephoneNumber ?? ''}`,
                        'Contactpersoon mobiel nummer': `${contactPerson?.mobilePhone?.dialingCode ? '+' + contactPerson?.mobilePhone?.dialingCode + ' ' : ''}${contactPerson?.mobilePhone?.telephoneNumber ?? ''}`,
                        'Notities': companyNotes,
                        'Contactpersson Notities': contactPersonNotes,
                        'Exact HJMG': company?.managingCompany?.exactHJMG ?? '',
                        'Exact Pretore': company?.managingCompany?.exactPretore ?? '',
                    };

                    // delete some fields for suppliers that dont exist
                    if (exportContentType === "suppliers") {
                        delete newRow['Type bedrijf'];
                        delete newRow['Branch'];
                    }

                    // Keep track of which rows had content to clean up later
                    if (customFilters) {
                        let notEmpty = objectFilter(newRow, el => el !== "");

                        keysWithValues.push(...Object.keys(notEmpty));
                    }

                    companyDataRows.push(newRow);
                }

                // push companyDataRows to csvData
                csvData.push(...companyDataRows);
            }


            if (customFilters) {
                // only look for keys that are unique
                let uniqKeys = [...new Set(keysWithValues)]

                // clean up rows that are empty
                for (let row in csvData) {
                    csvData[row] = Object.fromEntries(uniqKeys.map(col => [col, csvData[row][col]]));
                }
            }

            // available options at: https://github.com/alexcaza/export-to-csv#api
            const csvFileOptions = mkConfig({
                filename: `crm_${exportContentType === "companies" ? "bedrijven" : "leveranciers"}_export_${new Date().toLocaleDateString("nl", { year: "numeric", month: "2-digit", day: "2-digit" })}`, // date in DD-MM-YYY
                fieldSeparator: ';',
                decimalSeparator: 'locale',
                useKeysAsHeaders: true,
                showColumnHeaders: true,
                useBom: true,
            });

            const csv = generateCsv(csvFileOptions)(csvData);

            // download csv
            download(csvFileOptions)(csv);

            toast.update(
                toastId,
                {
                    render: "Succesvol geëxporteerd",
                    type: "success",
                    isLoading: false,
                    autoClose: 3000,
                    closeOnClick: true
                });

        }).catch((error) => {
            toast.update(
                toastId,
                {
                    render: `${exportContentType === "companies" ? "Bedrijven" : "Leveranciers"} kon niet geëxporteerd worden`,
                    type: "error",
                    isLoading: false,
                    autoClose: 3000,
                    closeOnClick: true
                });
            toast.error(`${exportContentType === "companies" ? "Bedrijven" : "Leveranciers"} kon niet geëxporteerd worden`);

            process.env.NODE_ENV === 'development' && console.error(error);
        });
    }
    return (
        <Popup
            title={"Export instellingen"}
            popupID={'crmExportFilters'}
        >

            <Select
                id={"exportType"}
                onChange={(e) => {
                    setExportSettings((prev) => ({
                        ...prev,
                        exportType: e,
                    }))
                }}
                value={exportSettings?.exportType}
                options={[
                    {value: 0, label: "Alle bedrijven"},
                    {value: 1, label: "Op CRM filter"},
                    {value: 2, label: "HJMG bedrijven", color: "var(--primary-hjmg)"},
                    {value: 3, label: "Pretore bedrijven", color: "var(--primary-pretore)"},
                ]}
                styles={colourStyles}
            ></Select>
            <Grid columns={1} alignItems={"center"} customColTemplate={"1fr"} justify gap={"5px"}>
                <FancyInput id={"withArchived"} type={"checkbox"} size={20} label={"Inclusief archief exporteren"} value={exportSettings?.withArchived ?? false} onChange={(e) => {
                    setExportSettings((prev) => ({
                        ...prev,
                        [e?.target?.id]: !prev?.[e?.target?.id],
                    }))
                }}/>
                {/* <ExportCompanies exportContentType={!isSupplier ? 'companies' : 'suppliers'} /> */}
                <FancyInput disabled id={"exportCustomFields"} type={"checkbox"} size={20} label={"Aangepaste velden exporteren"} value={exportSettings?.exportCustomFields ?? false} onChange={(e) => {

                    setExportSettings((prev) => ({
                        ...prev,

                        [e?.target?.id]: !prev?.[e?.target?.id],
                    }))
                }}/>

            </Grid>

            {exportSettings?.exportCustomFields === true &&
                <div style={{ marginTop: '2rem' }}>
                    <h4>Aangepaste velden</h4>
                    <hr style={{ margin: '0.5rem 0 1rem' }} />
                    {filterInputs}
                </div>
            }

            <hr style={{margin: '2rem 0'}}/>

            {exportSettings?.exportCustomFields === true &&
                <small style={{ marginBottom: '10px', display: 'block', textAlign: 'center' }}>Let op: Velden die ingeklapt staan zullen niet worden geëxporteerd!</small>
            }

            <button className={'btn'} style={{width: '100%'}} onClick={() => exportCompanies(exportSettings, fields)}>
                Exporteren <Icon name='arrow-down'/>
            </button>
        </Popup>
    )
}
