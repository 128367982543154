import { useContext, useEffect, useState } from "react";
import { FetchContext } from "App/Strapi/FetchContext";
import Popup, { closePopup } from "UI/App/Components/Popup/Popup";
import Select from "react-select";
import { formatTime, stripDiacritics } from "App/Util/format";
import User from "App/Strapi/User";
import Grid from "UI/App/Components/Grid/Grid";
import Icon from "UI/App/Components/Icon/Icon";
import { toast } from 'react-toastify';
import FancyInput from "UI/App/Components/Form/fancyInput";

export default function AddUsersToMachinesPopup({
    machine,
    afterUpdate
}) {
    const { authAxios } = useContext(FetchContext);
    const [machineName, setMachineName] = useState("")
    const [machineUsers, setMachineUsers] = useState([])
    const [removeCurrentMachineUsers, setRemoveCurrentMachineUsers] = useState(false)
    const [assignToMachineOptions, setAssignToMachineOptions] = useState([]);

    useEffect(() => {

        // set the name to give user feedback over current selected machine
        setMachineName(machine?.name)

        // get users that can operate machines
        if ((machine?.machineId !== null && !isNaN(machine?.machineId)) || machine?.machineId === 'handwerk') {
            const machineId = machine?.machineId;

            // clear last users
            setMachineUsers({})

            // actually get the users
            authAxios.get(`/calculations/resources/machines/${machineId}/operators`)
                .then(({ data }) => {
                    // format users for select
                    setAssignToMachineOptions(data.sort((a, b) => a.username > b.username ? 1 : -1).map(({ id, username, email, canOperate }) => ({
                        label: `${username} (${email})`,
                        value: id,
                        canOperate: canOperate
                    })));
                })
                .catch((error) => {
                    toast.error("Er is iets fout gegaan bij het ophalen van de werknemers")
                    setAssignToMachineOptions([]);
                    console.error(error);
                });
        }

    }, [machine])

    async function updateMachineUsers() {
        let projectsPromises = []

        for (let project of machine?.project) {
            let personsToSet = []

            // only add existing users if required
            if (removeCurrentMachineUsers) {
                personsToSet = [
                    ...machineUsers?.map((el) => el.value)
                ]
            } else {
                personsToSet = [
                    ...(project?.persons ?? []),
                    ...machineUsers?.map((el) => el.value)
                ]
            }

            // create a data body
            const body = {}
            // set the persons value
            body.persons = personsToSet

            projectsPromises.push(await authAxios.put(`/orders/${project.id}/planning/${project.uuid}`, body)
                .then((response) => {
                    return response
                })
            )

        }

        Promise.allSettled(projectsPromises).then((result) => {
            let fulfilledResults = result.filter((el) => el.status === "fulfilled").map((el) => el.value)

            if (fulfilledResults.length !== machine?.project.length) {
                // singular or multiple err msg
                toast.error("Er is een fout opgetreden bij het aanwijzen van de werknemers")
                closePopup();
            } else {
                toast.success("Werknemers toegewezen")
                afterUpdate();

                closePopup();
            }
        })
    }

    return (
        <Popup popupID={"addUsersInBulk"} title={`Werknemers toewijzen aan ${machineName ?? "Machine"}`}>
            <div className='input-group'>
                <label htmlFor='draggable-popup-assign'>
                    Werknemers toewijzen
                </label>

                <Select
                    id='draggable-popup-assign'
                    options={assignToMachineOptions}
                    filterOption={(option, input) => {
                        if (input?.length < 3) {
                            return option.data?.['canOperate'] ?? false;
                        } else {
                            return stripDiacritics(option.label.toLowerCase()).includes(stripDiacritics(input.toLowerCase()));
                        }

                    }}
                    value={machineUsers.length > 0 ? machineUsers : []}
                    onChange={User.hasRole(['admin', 'management', 'sales', 'productie manager']) ? (operators) => {
                        setMachineUsers(operators);
                    } : () => {
                    }}
                    isDisabled={!User.hasRole(['admin', 'management', 'sales', 'productie manager'])}
                    isMulti
                    placeholder='Selecteer werknemers'
                />
                <FancyInput id={"removeOtherPersons"} type={"checkbox"} size={20} label={"Overschrijf andere werknemers?"} value={removeCurrentMachineUsers ?? false} onChange={(e) => { setRemoveCurrentMachineUsers(!removeCurrentMachineUsers) }} />
            </div>
            <Grid columns={1} className='planning-popup__buttons'>
                <small style={{ marginBottom: '-10px', display: 'block', textAlign: 'center' }}>Let op: Werknemers worden alleen op de gekozen machine worden {removeCurrentMachineUsers ? "overschreven!" : "toegevoegd!"}</small>
                <button onClick={() => { updateMachineUsers() }} type='submit'>Toepassen <Icon name={'arrow-right'} /></button>
            </Grid>
        </Popup>
    )
}
