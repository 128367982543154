import { Fragment, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FetchContext } from 'App/Strapi/FetchContext';
import Container from "UI/App/Components/Container/Container"
import Table from "UI/App/Components/Table/Table"
import { usePagination } from "UI/App/Components/Pagination/Pagination";
import OrderTimerEditPopup from './OrderTimerEditPopup';
import User from "App/Strapi/User";
import IF from "UI/App/Components/Conditional/IF";
import { getSecondsMinusBreaks } from "App/Util/transform";
import { formatDateWithoutTime, formatMinutes, formatTime } from 'App/Util/format';
import Icon from 'UI/App/Components/Icon/Icon';
import { toast } from 'react-toastify';
import { stringify } from 'qs';
import { openPopup } from "UI/App/Components/Popup/Popup";

export default function ViewAttendance() {
    const params = useParams();
    const { authAxios } = useContext(FetchContext);
    // create a state for the table data
    const [tableData, setTableData] = useState([]);

    const [timerOpenInPopup, setTimerOpenInPopup] = useState(null);

    // create a state for the table columns (sort order and column field)
    const [tableColumns, setTableColumns] = useState({
        sort: {
            column: 'id',
            direction: 'desc'
        },
        fields: [

            {
                name: 'Datum', field: 'start', sortable: true,
            },
            {
                name: 'Gebruiker', field: 'user.username', sortable: true,
            },
            {
                name: 'Aantal uren', field: '', sortable: false,
            },
            {
                name: 'Klant', field: 'order.company.name', sortable: true,
            },
            {
                name: 'Order', field: 'order.name', sortable: true,
            },
            {
                name: 'Machine', field: 'timerFor', sortable: true,
            }
        ]
    });

    // Enable pagination
    const {
        paging,
        filtering,
        currentPage,
        resultsPerPage,
        setTotalPages,
        setTotalResults,
        filterQuery,
        filterValues,
        paginationStateLoaded,
        setFilterValues,
        reloadData
    } = usePagination({
        storageKey: 'order-timers',
        searchSettings: {
            enabled: true,
            strapiFields: [
                '[user][username]',
                '[order][number]',
                '[order][description]',
                '[order][company][name]',
            ]
        },
        filters: [
            {
                label: "van",
                name: "start",
                type: "date",
                callbackFunction: (e) => {

                    if (e.currentValue === "" || e.currentValue === undefined) return;
                    // create the custom filter object
                    return {
                        [e.strapiFilterField]: {
                            [e.strapiFilter]: e.currentValue
                        }
                    }
                },
                strapiFilterField: 'start',
                strapiFilter: '$gte'
            },
            {
                label: "tot",
                name: "end",
                type: "date",
                callbackFunction: (e) => {
                    if (e.currentValue === "" || e.currentValue === undefined) return;
                    // create the custom filter object
                    return {
                        $or: [
                            {
                                [e.strapiFilterField]: {

                                    [e.strapiFilter]: e.currentValue,

                                }
                            },
                            {// if a timer doesn't have an end we can accept null as 'now'
                                [e.strapiFilterField]: {
                                    $null: true
                                }
                            }
                        ]

                    }
                },
                strapiFilterField: 'end',
                strapiFilter: '$lte'
            }
        ],
        buttonCollapseBreakpoint: 1960,
        resultsPerPageBreakpoint: 1960,
        paginationBreakpoint: 1960,
        resultCountBreakpoint: 1850,
        filterPopupBreakpoint: 99999, // As requested all filters should be in a popup, setting the width to something high will enforce this.
        showFilterClearButton: true,
    });

    // get the table data using the useEffect hook
    useEffect(() => {

        // wait for pagination
        if (!paginationStateLoaded) return;
        getTimers();

    }, [currentPage, resultsPerPage, authAxios, setTotalPages, setTotalResults, filterQuery, tableColumns, paginationStateLoaded]);

    async function getTimers() {
        // build the pagination query
        let query = buildSearchParams(resultsPerPage, currentPage, tableColumns);
        authAxios
            .get(`/orders/timers?${query}&${filterQuery}`)
            .then(({ data }) => {
                // create temp array to store formated data in
                let tableDataArray = []

                const hasEditRights = User.hasRole(['admin', 'management', 'productie manager', 'sales']);

                // format data to be usable for table component
                for (const entry of data.results) {

                    // calculate the hours spent on this order
                    const entrySeconds = getSecondsMinusBreaks(entry.start, entry.end, entry.breaks);
                    let hours = formatTime(entrySeconds / 1000 / 60 / 60);



                    let dayStart = new Date(entry.start).setUTCHours(0, 0, 0, 0);
                    let dayEnd = new Date(entry.end).setUTCHours(0, 0, 0, 0);
                    let warnings = []

                    // get the timeOffset for start and end, these could be diffrent
                    let timeOffsetStart = (new Date(entry.start).getTimezoneOffset() * 60 * 1000) * -1;
                    let timeOffsetEnd = (new Date(entry.end).getTimezoneOffset() * 60 * 1000) * -1;

                    // mabey do more/less with this at some point?
                    if (dayStart !== dayEnd && dayEnd !== 0) {
                        warnings.push(<Icon name={"alarm-exclamation"} />)
                    }

                    let msBetweenStart = (new Date(entry.start).getTime() - new Date(dayStart).getTime()) + timeOffsetStart;
                    let msBetweenEnd = (new Date(entry.end).getTime() - new Date(dayEnd).getTime()) + timeOffsetEnd

                    let hoursBetweenStart = msBetweenStart / 1000 / 60 / 60;
                    let hoursBetweenEnd = msBetweenEnd / 1000 / 60 / 60;

                    tableDataArray.push(
                        {
                            attributes: {
                                id: entry.id
                            },
                            data: [

                                formatDateWithoutTime(entry?.start) ?? '-', // date
                                entry?.user?.username ?? '-',      // user
                                `${formatTime(hoursBetweenStart)}` ?? '-', // date,
                                `${entry.end === null ? "-" : formatTime(hoursBetweenEnd)}` ?? '-', // date,
                                <div>{warnings} {hours}</div>, ,   // amount of hours
                                <IF condition={hasEditRights}>
                                    <button className={'btn--transparent'} onClick={() => editTimer(entry)}><Icon name={'pen-to-square'} /></button>
                                </IF>
                            ],

                        }
                    )
                }
                // save table data in useState
                setTableData(tableDataArray)

                // Set pagination data
                const paginationData = data?.pagination;
                setTotalPages(paginationData?.pageCount);
                setTotalResults(paginationData?.total);

            }).catch((exception) => {
                toast.error(
                    `Er is iets fout gegaan met het ophalen van de timers ${exception?.response?.status ? `(${exception.response?.status})` : ''
                    } `
                );

                console.error(exception);
            });
    }

    function buildSearchParams(resultsPerPage, currentPage, tableColumns) {
        let queryParams = {

            page: currentPage,
            pageSize: resultsPerPage,
            sort: `${tableColumns.sort.column}:${tableColumns.sort.direction}`,
            filters: {
                order: {
                    id: {
                        $null: true
                    }

                }

            }
        };

        return stringify(queryParams, {
            encodeValuesOnly: true // prettify URL
        });
    }

    function editCallback(timer) {
        getTimers();
    }

    function editTimer(timer) {
        setTimerOpenInPopup(timer);
        openPopup('editTimerPopup');
    }

    return (
        <Fragment>
            <div>
                {filtering}
                <Container>
                    <Table
                        headers={[
                            'Datum',
                            'Medewerker',
                            'Inkloktijd',
                            'Uitkloktijd',
                            'totale tijd',
                            ''
                        ]}
                        structure={[

                            "20%",
                            "20%",
                            "20%",
                            "20%",
                            "20%",
                            "80px"

                        ]}
                        data={tableData}
                        tableColumns={tableColumns}
                        setTableColumns={setTableColumns}
                        hover
                        border='row'
                        overflowText={true}
                    />
                </Container>
                {paging}
            </div>

            <OrderTimerEditPopup timer={timerOpenInPopup} callback={editCallback} />
        </Fragment>

    )
}