import {useCallback, useContext, useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {stringify} from 'qs';
import capitalize from 'lodash/capitalize';
import {FetchContext} from 'App/Strapi/FetchContext';
import {b64toBlob} from 'App/Util/transform';
import Table from 'UI/App/Components/Table/Table';
import Icon from 'UI/App/Components/Icon/Icon';
import {usePagination} from 'UI/App/Components/Pagination/Pagination';
import Button from "../../../../Components/Button/Button";
import Popup, {closePopup, openPopup} from "../../../../Components/Popup/Popup";
import useWindowDimensions from 'App/Util/useWindowDimensions';
import {formatCurrency} from 'App/Util/format';

// set status options
const statusses = [
    'concept',
    'order',
    'uitgebracht',
    'herzien',
    'geaccepteerd',
    'afgewezen prijs',
    'afgewezen planning',
    'afgewezen klant',
    'afgewezen overig',
    'verlopen'
];
const quoteStatusOptions = [];
for (const status of statusses) {
    quoteStatusOptions.push(
        <option key={status} value={status}>
            {capitalize(status)}
        </option>
    );
}

// set base quotes fetch query
const quotesQuery = {
    populate: {
        company: {
            fields: ['name']
        },
        calculations: {
            fields: ['owner']
        },
        priceData: {
            fields: ['amount', 'price']
        }
    },
    sort: 'id:desc'
};

export default function Quotes() {
    const { authAxios } = useContext(FetchContext);
    const navigate = useNavigate();
    const { width } = useWindowDimensions();

    // create a state for the table data
    const [tableData, setTableData] = useState([]);
    const [quotes, setQuotes] = useState([]);

    const [selectedQuote, setSelectedQuote] = useState(null);
    const {
        paging,
        filtering,
        currentPage,
        resultsPerPage,
        setTotalPages,
        setTotalResults,
        filterQuery
    } = usePagination({
        storageKey: 'qoute',
        searchSettings: {
            enabled: true,
            strapiFields: ['number', '[company][name]', 'description']
        },
        filters: [
            {
                name: 'status',
                type: 'select',
                options: [
                    { value: '*', name: 'Alle' },
                    { value: 'concept', name: 'Concept' },
                    { value: 'order', name: 'Order' },
                    { value: 'uitgebracht', name: 'Uitgebracht' },
                    { value: 'herzien', name: 'Herzien' },
                    { value: 'geaccepteerd', name: 'Geaccepteerd' },
                    { value: 'afgewezen prijs', name: 'Afgewezen prijs' },
                    { value: 'afgewezen planning', name: 'Afgewezen planning' },
                    { value: 'afgewezen klant', name: 'Afgewezen klant' },
                    { value: 'afgewezen overig', name: 'Afgewezen overig' },
                    { value: 'verlopen', name: 'Verlopen' }
                ]
            }, {
                name: 'owner',
                type: 'select',
                options: [
                    { name: "Alle bedrijven", value: '*' },
                    { name: "HJMG", value: "hjmg" },
                    { name: "Pretore", value: "pretore" },
                ],
                callbackFunction: callbackFilter
            }
        ]
    });
    function callbackFilter(e) {

        // gaurd against no value or default value
        if (e?.currentValue === undefined || e?.currentValue === "*") return;

        // create the custom filter object
        let returnObject = {
            calculations: {
                owner: e.currentValue

            }
        }
        // return the custom object
        return returnObject;
    }
    const getQuotePDF = useCallback(
        (quoteId) => {
            authAxios
                .get(`/quotes/${quoteId}?fields=pdfBase64`)
                .then(({ data }) => {
                    if (data.data?.pdfBase64) {
                        const blob = b64toBlob(data.data.pdfBase64, 'application/pdf');
                        const blobUrl = URL.createObjectURL(blob);

                        window.open(blobUrl);
                    }
                })
                .catch((exception) => {
                    toast.error(
                        `Er is iets fout gegaan ${exception?.response?.status ? `(${exception.response?.status})` : ''
                        }`
                    );
                    console.error(exception);
                });
        },
        [authAxios]
    );

    const updateStatus = useCallback(
        (value, quoteId, index) => {
            authAxios
                .put(`/quotes/${quoteId}`, {
                    data: {
                        status: value
                    }
                })
                .then(({ data }) => {
                    // update quotes state
                    setQuotes((prev) => {
                        prev[index].status = value;
                        return [...prev];
                    });

                    toast.success(`Offerte: ${data.data.number} status omgezet naar ${value}`);
                })
                .catch((exception) => {
                    console.error(exception);
                    toast.error(`Er ging iets mis met het bewerken van offerte.`);
                });
        },
        [authAxios]
    );

    const getTableData = useCallback(() => {
        // add our pagination value to the query
        quotesQuery.pagination = {
            pageSize: resultsPerPage,
            page: currentPage
        };

        // fetch the quotes
        authAxios
            .get(`/quotes?${stringify(quotesQuery)}&${filterQuery}`)
            .then(({ data }) => {
                // set the fetched quotes in the state
                setQuotes(data.data);

                setTotalPages(data.meta.pagination.pageCount);
                setTotalResults(data.meta.pagination.total);
            })
            .catch((exception) => {
                toast.error(
                    `Er is iets fout gegaan ${exception?.response?.status ? `(${exception.response?.status})` : ''
                    }`
                );
                console.error(exception);
            });
    }, [authAxios, currentPage, filterQuery, resultsPerPage, setTotalPages, setTotalResults]);

    /**
     * Sets the rows of the table when the fetched quotes state changes
     */
    const setTableRows = useCallback(() => {
        // Set table data:
        const tableData = [];

        // loop through all the records
        for (const index in quotes) {
            const quote = quotes[index];

            tableData.push({
                attributes: {
                    onClick: () => {
                        navigate(`/quotes/${quote.id}${quote.status !== 'Concept' ? '' : '/edit'}`);
                    },
                    key: quote.id // use unique id over array index to properly update the DOM
                },
                data: [
                    <div className={'owner-indicator'}>
                        {quote?.calculations[0].owner !== null ? quote?.calculations[0].owner.toLowerCase() === 'hjmg' ? <span className={'circle circle-hjmg'}></span> : <span className={'circle circle-pretore'}></span> : <></>}
                    </div>,
                    quote.number,
                    quote?.company?.name ? (
                        <Link
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                            to={`/crm/companies/${quote?.company?.id}`}
                        >
                            {quote?.company?.name}
                        </Link>
                    ) : (
                        'Geen bedrijf..'
                    ),
                    quote?.description,
                    quote.priceData.reduce((min, entry) => entry.price < min ? entry.price : min, quote.priceData[0])?.price ? formatCurrency(quote.priceData.reduce((min, entry) => entry.price < min ? entry.price : min, quote.priceData[0])?.price) : '',
                    (quote?.endDate === undefined || quote?.endDate === null ? <span style={{ textAlign: 'center', color: 'lightgrey' }}>-</span> : (width >= 1600 ? new Date(quote?.endDate).toLocaleDateString('nl-NL', { year: 'numeric', month: 'long', day: 'numeric' }) : new Date(quote?.endDate).toLocaleDateString('nl-NL'))),
                    <select
                        value={quote?.status ?? ''}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        onChange={(e) => {
                            updateStatus(e.target.value, quote.id, index);
                        }}
                    >
                        {quoteStatusOptions}
                    </select>,
                    <div className='table__action-buttons'>
                        {/*<Link*/}
                        {/*    data-title={quote?.status?.toLowerCase() !== 'concept' ? 'Bekijken' : 'Bewerken'}*/}
                        {/*    onClick={(e) => e.stopPropagation()}*/}
                        {/*    className='btn btn--transparent'*/}
                        {/*    to={`/quotes/${quote.id}${quote?.status?.toLowerCase() !== 'concept' ? '' : '/edit'}`}*/}
                        {/*    key='0'*/}
                        {/*>*/}
                        {/*    <Icon name={quote?.status?.toLowerCase() !== 'concept' ? 'eye' : 'pencil'} />*/}
                        {/*</Link>*/}

                        {width >= 350 && <Button data-title={'Offerte kopiëren'} className={'btn btn--transparent'} onClick={(e) => {
                            e.stopPropagation();
                            openPopup("CopyOrRevision")
                            setSelectedQuote(quote.id)
                        }}><Icon name={'copy'} /></Button>}
                        {/*<Link*/}
                        {/*    data-title={'Kopiëren'}*/}
                        {/*    onClick={(e) => e.stopPropagation()}*/}
                        {/*    className='btn btn--transparent'*/}
                        {/*    to={`/quotes/${quote.id}/copy`}*/}
                        {/*    key='1'*/}
                        {/*>*/}
                        {/*    <Icon name='copy'/>*/}
                        {/*</Link>*/}

                        {quote.pdfBase64 !== null && quote.pdfBase64 !== '' && (
                            <button
                                data-title='Downloaden'
                                className={'btn btn--transparent'}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    getQuotePDF(quote.id);
                                }}
                                key='2'
                            >
                                <Icon name='download' />
                            </button>
                        )}

                        {width >= 350 ? <Link
                            data-title='Bevestigen'
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                            className='btn btn--transparent'
                            to={`/orders/confirmations/${quote.id}`}
                            key='3'>
                            <Icon name='file-check' />
                        </Link>
                            : <></>}
                    </div>
                ]
            });
        }

        setTableData(tableData);
    }, [quotes, getQuotePDF, navigate, updateStatus, width]);

    // get the table data using the useEffect hook
    useEffect(() => {
        getTableData();
    }, [getTableData]);

    // set the table rows on quotes state update
    useEffect(() => {
        setTableRows();
    }, [setTableRows]);

    return (
        <div>
            <Popup popupID='CopyOrRevision' title={'Kopie of revisie'}>
                <Button onClick={() => {
                    closePopup('CopyOrRevision')
                    navigate(`/quotes/${selectedQuote}/copy`)
                }}>
                    Kopie
                </Button>
                <Button onClick={() => {
                    closePopup('CopyOrRevision')
                    navigate(`/quotes/${selectedQuote}/revision`)
                }}>
                    Revisie
                </Button>
            </Popup>
            {filtering}
            <Table
                headers={['', 'Offerte nummer', 'Klant', 'Omschrijving', "Minimale waarde", "Geldig tot", 'Status', '']}
                structure={{
                    350: ['40px', '100%', 0, 0, 0, 0, 0, '50px'],
                    550: ['40px', '100%', 0, 0, 0, 0, 0, '120px'],
                    700: ['40px', '170px', '100%', "80px", "80px", 0, '120px'],
                    850: ['40px', '170px', '150px', '100%', "80px", '80px', 0, '120px'],
                    1600: ['40px', '170px', '150px', '100%', '100px', '100px', '150px', '120px'],
                    1700: ['40px', '170px', '150px', '100%', '200px', '200px', '150px', '120px'],
                    default: ['40px', '170px', '250px', '100%', '200px', '200px', '200px', '120px']
                }}
                data={tableData}
                hover
                border='row'
                padding='m'
                paddingBreakpoint='1700'
                buttonCollapseBreakpoint='1600'
                columnOverflowExceptions={[7]}
                columnTranslationExceptions={[1, 2]}
            />
            {paging}
        </div>
    );
}
